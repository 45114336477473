import React, { memo, useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Typography
  } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { colors } from '../../../helpers/colors';
import { getVisits } from '../../../fetch/visits';


const useStyles = makeStyles((theme) => ({
    statsContainer: {
        display: 'flex',
    },
    statsText: {
        width: '100%',
        margin: 5,
    },
    statsText2: {
        margin: 5,
        display: 'flex',
        alignItems: 'center'
    },
    statsTextCount: {
        margin: 5,
        color: colors.red
    },
    activeContainer: {
        width: '100%',
        backgroundColor: colors.white,
        padding: 30
    },
    icon: {
        cursor: 'pointer'
    },
    loader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    mainContent: {
        width: '100%',
        maxHeight: '50vh',
        borderRadius: 15,
        border: 'solid 2px #000000',
        padding: 15,
        overflowY: 'auto',
        overflowX: 'hidden'
    }
}));

export const StatsPanel = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsError(false);
        setIsLoading(true);
        
        getVisits(props.auth.token)
        .then(res => {
            if(res.status < 205) 
                props.setVisits(res.data);
            else 
                throw new Error('Not allowed response status.');
        })
        .catch(err => {
            setIsError(true);
            console.log(err)
        })
        .finally(() => setIsLoading(false))

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const calcSumVisits = () => {
    //     let visits = 0;
    //     props.visits.map(referrer => referrer.count).forEach(count => visits += count)

    //     return visits;
    // }

    const renderContent = err => {
        if(err) 
            return (
                <Typography
                    className={classes.statsText2}
                    align="start"
                    variant="h6"
                >
                    Przepraszamy, brak wyników.
                </Typography>
            )

        return <Box className={classes.mainContent}>
            {props.visits.map(referrer => {
                if(referrer.referrer === '') return <></>
                if(referrer.referrer.includes('localhost')) return <></>

                return (
                    <Typography
                        className={classes.statsText2}
                        align="start"
                        variant="p"
                    >
                        {`Wizyty z ${referrer.referrer}: `}
                        <Typography
                            className={classes.statsTextCount}
                            align="start"
                            variant="p"
                        >
                            {referrer.count}
                        </Typography>
                    </Typography>
            )})}
        </Box>
        
    }

    return props.isLogIn && [
        <Box className={classes.statsContainer}>
            <KeyboardBackspaceIcon 
                className={classes.icon} 
                onClick={() => props.setTarget(undefined)}
            />
            <Typography
                className={classes.statsText}
                align="center"
                variant="h5"
            >
                Statystyki witryny
            </Typography>
        </Box>,
        <Box className={classes.activeContainer}>
            {
                isLoading ? 
                    <Box className={classes.loader} >
                        <CircularProgress />
                    </Box>
                :
                    renderContent(isError)
            }
        </Box>
    ]
})
