import React, { memo } from 'react';
import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../helpers/colors';


const useStyles = makeStyles((theme) => ({
    copyrights: {
        color: colors.white,
        margin: 15
    }
}))


export const Copyrights = memo((props) => {
    const theme = useTheme();
    const classes = useStyles(theme);


    return (
        <Typography className={classes.copyrights} align="center">
            {`${new Date().getFullYear()} ©`}
        </Typography>
    )
})