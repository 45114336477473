import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from './App';
import { MainLoader } from './components/MainLoader/MainLoader';
import './i18n.js'
import './style.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/home">
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: true,
                isHistory: false,
                isSearch: false,
                isGeoportal: false,
                isGallery: false,
                isProject: false,
                isContact: false,
                isAdminPanel: false
              }}
              path="/home"
            />
          </Suspense>
        </Route>
        <Route exact path="/home">
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: true,
                isHistory: false,
                isSearch: false,
                isGeoportal: false,
                isGallery: false,
                isProject: false,
                isContact: false,
                isAdminPanel: false
              }}
              path="/home"
            />
          </Suspense>
        </Route>
        <Route exact path="/history">
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: false,
                isHistory: true,
                isSearch: false,
                isGeoportal: false,
                isGallery: false,
                isProject: false,
                isContact: false,
                isAdminPanel: false
              }}
              path="/history"
            />
          </Suspense>
        </Route>
        <Route exact path="/search">
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: false,
                isHistory: false,
                isSearch: true,
                isGeoportal: false,
                isGallery: false,
                isProject: false,
                isContact: false,
                isAdminPanel: false
              }}
              path="/search"
            />
          </Suspense>
        </Route>
        <Route exact path="/geoportal">
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: false,
                isHistory: false,
                isSearch: false,
                isGeoportal: true,
                isGallery: false,
                isProject: false,
                isContact: false,
                isAdminPanel: false
              }}
              path="/geoportal"
            />
          </Suspense>
        </Route>
        <Route exact path="/gallery">
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: false,
                isHistory: false,
                isSearch: false,
                isGeoportal: false,
                isGallery: true,
                isProject: false,
                isContact: false,
                isAdminPanel: false
              }}
              path="/gallery"
            />
          </Suspense>
        </Route>
        <Route exact path="/project">
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: false,
                isHistory: false,
                isSearch: false,
                isGeoportal: false,
                isGallery: false,
                isProject: true,
                isContact: false,
                isAdminPanel: false
              }}
              path="/project"
            />
          </Suspense>
        </Route>
        <Route exact path='/contact'>
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: false,
                isHistory: false,
                isSearch: false,
                isGeoportal: false,
                isGallery: false,
                isProject: false,
                isContact: true,
                isAdminPanel: false
              }}
              path="/contact"
            />
          </Suspense>
        </Route>
        <Route exact path='/admin'>
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: true,
                isHistory: false,
                isSearch: false,
                isGeoportal: false,
                isGallery: false,
                isProject: false,
                isContact: false,
                isAdminPanel: true
              }}
              path="/admin"
            />
          </Suspense>
        </Route>
        <Redirect to="/home">
          <Suspense fallback={<MainLoader />}>
            <App 
              useSuspense 
              menu={{
                isHome: true,
                isHistory: false,
                isSearch: false,
                isGeoportal: false,
                isGallery: false,
                isProject: false,
                isContact: false,
                isAdminPanel: false,
              }}
              path="/home"
            />
          </Suspense>
        </Redirect>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
