import React, { memo, useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Divider,
    Typography
  } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';
import CloseIcon from '@material-ui/icons/Close';
import { LogIn } from './LogIn';
import { MainControl } from './MainControl';
import { DbForm } from './DbForm';
import { StatsPanel } from './StatsPanel';
import { DbRecords } from './DbRecords';
import { login } from '../../../fetch/login';


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        alignSelf: 'baseline',
        margin: 20,
        marginTop: 80
    },
    logInContainer: {
        width: '75vw',
        backgroundColor: colors.white,
        padding: 30,
        
    },
    activeContainer: {
        width: '100%',
        backgroundColor: colors.white,
        padding: 30
    },
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center'
    },
    headerText: {
        width: '100%'
    },
    icon: {
        cursor: 'pointer'
    },
    divider: {
        margin: '20px 0',
        backgroundColor: colors.black
    },
    helper: {
        color: colors.red
    },
    loader: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));



export const AdminPanel = memo((props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [isLogIn, setIsLogIn] = useState(false);
    const [logInForm, setLogInForm] = useState({
        username: undefined,
        password: undefined
    });
    const [error, setError] = useState(false);
    const [target, setTarget] = useState(undefined);
    const [dbList, setDbList] = useState([]);
    const [selectedDb, setSelectedDb] = useState(null);
    const [selectedDbPersons, setSelectedDbPersons] = useState([]);

    const [dbForm, setDbForm] = useState({
        ID_grob: '',
        Rodzaj_grobu: '',
        Kategoria_grobu: '',
        Dokres_grobu: '',
        Inskrypcja: '',
        Cmentarz: '',
        osoby: []
    });

    const [visits, setVisits] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(props.auth?.token !== '')
            setIsLogIn(true);
        else
            setIsLogIn(false);
    }, [props.auth])

    const endLoading = fun => setTimeout(() => fun ? fun() : setIsLoading(false), 1000);

    const logInHandler = () => {
        setIsLoading(true);
        setError(false);
        if(logInForm.username?.length && logInForm.password?.length) {
            login(logInForm)
                .then(res => {
                    if(res.status === 200) 
                        props.setAuth(res.data);
                })
                .catch(err => {
                    setError(true);
                    console.log(err);
                })
                .finally(() => {
                    endLoading();
                })
        }
        else {
            endLoading(() => {
                setError(true);
                setIsLoading(false);
            });
        }
    }

    const logOutHandler = () => {
        if(isLogIn) {
            props.setAuth({token: ''})
            setLogInForm({
                username: undefined,
                password: undefined
            })
        }
    }

    const onChangeInput = e => {
        const id = e.target.id;
        const val = e.target.value;

        if(id.includes('username'))
            setLogInForm({
                ...logInForm,
                username: val
            })
        else 
            setLogInForm({
                ...logInForm,
                password: val
            })
    }

    const renderTarget = () => {
        switch (target) {
            case 'db':
                return <DbRecords 
                    setTarget={setTarget}
                    dbForm={dbForm}
                    setDbForm={setDbForm}
                    dbList={dbList}
                    setDbList={setDbList}
                    isLogIn={isLogIn}
                    auth={props.auth}
                    setSelectedDb={setSelectedDb}
                    setSelectedDbPersons={setSelectedDbPersons}
                />
            case 'stats':
                return <StatsPanel 
                    setTarget={setTarget}
                    setVisits={setVisits}
                    visits={visits}
                    isLogIn={isLogIn}
                    auth={props.auth}
                />
            case 'create': 
                return <DbForm 
                    dbForm={dbForm}
                    setDbForm={setDbForm}
                    setTarget={setTarget}
                    setDbList={setDbList}
                    dbList={dbList}
                    isLogIn={isLogIn}
                    auth={props.auth}
                    initId={selectedDb}
                    initPersonId={selectedDbPersons}
                    target={target}
                />
            case 'edit': 
                return <DbForm 
                    dbForm={dbForm}
                    setDbForm={setDbForm}
                    setTarget={setTarget}
                    setDbList={setDbList}
                    dbList={dbList}
                    isLogIn={isLogIn}
                    auth={props.auth}
                    initId={selectedDb}
                    initPersonId={selectedDbPersons}
                    target={target}
                    setIsBackdrop={props.setIsBackdrop}
                    isBackdrop={props.isBackdrop}
                />
            default:
                return <MainControl 
                    logOutHandler={logOutHandler}
                    setTarget={setTarget}
                />
        }
    }


    return (
        <Box className={classes.mainContainer} >
            <Box className={classes.logInContainer} >
                <Box className={classes.header} >
                    <Typography
                        className={classes.headerText}
                        align="center"
                        variant="h4"
                    >
                        {!isLogIn ? 'Zaloguj się' : 'Panel administracyjny'}
                    </Typography>
                    <CloseIcon 
                        className={classes.icon}
                        onClick={() => {
                            setTimeout(() => {
                                setTarget('');
                            }, 500)
                            props.setMenu({
                                ...props.menu,
                                isAdminPanel: false
                            })
                        }}
                    />
                </Box>
                <Divider className={classes.divider} />
                {!isLogIn ?
                        <LogIn 
                            logInHandler={logInHandler}
                            onChangeInput={onChangeInput}
                            logInForm={logInForm}
                            setLogInForm={setLogInForm}
                            error={error}
                            isLoading={isLoading}
                        />
                    :
                        <Box className={classes.activeContainer} >
                            {isLoading ? 
                                    <Box className={classes.loader} >
                                        <CircularProgress />
                                    </Box>
                                :
                                    renderTarget()
                            }
                        </Box>
                }
            </Box>
        </Box>
    )
})
