import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    TextField,
    Button,
    MenuItem,
    Tooltip
} from '@material-ui/core'
// import { availableRegions } from '../../helpers/availableRegions';
import { toShortRegion } from '../../helpers/convertValue';
import { getCemeteries, getRegions } from '../../fetch/lists';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    cell: {
        padding: 10
    },
    input: {
        height: 56
    },
    form: {
        width: '100%',
    }
}))

export const SearchForm = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();

    const [availableRegions, setAvailableRegions] = useState([]);
    const [availableCemeteries, setAvailableCemeteries] = useState([]);


    useEffect(() => {
        getCemeteries()
        .then(res => {
            if(res.status < 205)
                setAvailableCemeteries(res.data)
            else
                throw new Error('not acceptable')
        })
        .catch(err => {
            console.log(err)
        })

        getRegions()
        .then(res => {
            if(res.status < 205)
                setAvailableRegions(res.data)
            else
                throw new Error('not acceptable')
        })
        .catch(err => {
            console.log(err)
        })

    }, [])

    const handleChange = e => {
        switch(e.target.id){
            case 'firstName':
                props.setFirstName(e.target.value);
                break;
            case 'lastName':
                props.setLastName(e.target.value);
                break;
            default:
                break;
        }
    }

    const handleChangeRegion = e => props.setRegion(e.target.value);

    const handleChangeCemetery = e => props.setCemetery(e.target.value)

    return (
        <form
            className={classes.form}
            onSubmit={props.handleClick}
        >
        <Grid container alignItems='center'>
            <Grid item xs={12} sm={2} className={classes.cell}>
                <Tooltip
                    title="Aby wyszukać, możesz wpisać fragment imienia."
                    placement="top"
                >
                    <TextField
                        id="firstName"
                        label={t('search.firstName')}
                        value={props.firstName}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            className: classes.input
                        }}
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={12} sm={2} className={classes.cell}>
                <Tooltip
                    title="Aby wyszukać, możesz wpisać fragment nazwiska."
                    placement="top"
                >
                    <TextField
                        id="lastName"
                        label={t('search.lastName')}
                        value={props.lastName}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            className: classes.input
                        }}
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={12} sm={2} className={classes.cell}>
                <Tooltip
                    title="Wybierz rejon."
                    placement="top"
                >
                    <TextField
                        id="region"
                        label={t('search.region')}
                        select
                        value={props.region}
                        onChange={handleChangeRegion}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            className: classes.input
                        }}
                        disabled={!availableRegions.length}
                    >
                        <MenuItem key='default' value=''>
                            -
                        </MenuItem> 
                        {availableRegions.map(r => 
                            <MenuItem key={r} value={r}>
                                {toShortRegion(r)}
                            </MenuItem>   
                        )}
                    </TextField>
                </Tooltip>
            </Grid>
            <Grid item xs={12} sm={2} className={classes.cell}>
                <Tooltip
                    title="Wybierz cmentarz."
                    placement="top"
                >
                    <TextField
                        id="cemetery"
                        label={'Cmentarz'}
                        select
                        value={props.cemetery}
                        onChange={handleChangeCemetery}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            className: classes.input
                        }}
                        disabled={!availableCemeteries.length}
                    >
                        <MenuItem key='default' value=''>
                            -
                        </MenuItem> 
                        {availableCemeteries.map(r => 
                            <MenuItem key={r} value={r}>
                                {r}
                            </MenuItem>   
                        )}
                    </TextField>
                </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.cell}>
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    className={classes.input}
                    type="submit"
                >
                    {t('search.search')}
                </Button>
            </Grid>
        </Grid>
        </form>
    )
}