import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { useSpeechSynthesis } from 'react-speech-kit';
import {
  Typography
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../helpers/colors';


const useStyles = makeStyles((theme) => ({
    btnText: {
        color: colors.white,
        fontWeight: 700,
        fontSize: 16,
        textShadow: '1px  1px 2px black, 1px -1px 2px black, -1px  1px 2px black, -1px -1px 2px black',
        [theme.breakpoints.down(1300)]: {
            fontSize: 14,
        },
        [theme.breakpoints.down(1200)]: {
            fontSize: 12,
        },
        [theme.breakpoints.down(1110)]: {
            fontSize: 16,
        }
    },

}))


export const ToolbarText = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    // const { speak, cancel } = useSpeechSynthesis();

    return (
        <Typography
            className={classes.btnText}
        >
            {props.translate}
        </Typography>
    )
}