export const _breakpoints = {
    laptopL: {
        size: 1441,
        fontSizeMultiply: 1.3,
    },
    laptop: {
        size: 1025,
        fontSizeMultiply: 1.7,
    },
    tablet: {
        size: 769,
        fontSizeMultiply: 2.2,
    },
    mobileL: {
        size: 426,
        fontSizeMultiply: 3.2,
    },
    mobileM: {
        size: 376,
        fontSizeMultiply: 3.2,
    }
}