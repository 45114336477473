import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { 
    Box, 
    IconButton, 
    Typography 
} from '@material-ui/core';
import Resizer from "react-image-file-resizer";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { colors } from '../../../helpers/colors';


const useStyles = makeStyles((theme) => ({
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '2px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 250,
        maxHeight: 300,
        padding: 4,
        boxSizing: 'border-box',
        backgroundColor: '#eaeaea'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    },
    iconButton: {
        maxWidth: 15, 
        maxHeight: 15, 
        position: 'absolute'
    },
    icon: {
        color: colors.black,
        backgroundColor: colors.red_trans2,
        borderRadius: 15,
    },
    dropZone: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `2px solid ${colors.black_trans}`,
        borderRadius: 15,
        minHeight: 100,
        cursor: 'pointer'
    }
}));


export const DropZonePreviews = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [files, setFiles] = useState(props.images ? props.images : []);
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            acceptedFiles.map(file => fileChangedHandler(file))

            const _imgs = [
                ...files,
                ...acceptedFiles.map(file => {
                    return Object.assign(file, {
                        original: URL.createObjectURL(file)
                    })
                })
            ];
            setFiles(_imgs);
        }
    });

    const base64 = props.images.map((file, index) => {
        return <Box 
            className={classes.thumb} 
        >
            <Box className={classes.thumbInner}>
                <IconButton 
                    onClick={() => props.setImgs(props.images.filter((file, i) => index !== i))}
                    className={classes.iconButton}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>
                <img
                    alt={``}
                    src={file.original ? file.original : file}
                    className={classes.img}
                />
            </Box>
        </Box>
    })

    const fileChangedHandler = (file) => {
        if (file) {
            try {
                Resizer.imageFileResizer(
                    file,
                    1920,
                    1080,
                    "JPEG",
                    100,
                    0,
                    (uri) => {
                        props.setImgs(prev => [...prev, uri])
                    },
                    "base64",
                    200,
                    200
                );
            } 
            catch (err) {
                console.log(err);
            }
        }
    }

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.map(file => URL.revokeObjectURL(file.original));

    }, [files]);

    return (
        <section className="container">
            <Box {...getRootProps({className: `dropzone ${classes.dropZone}`})}>
                <input {...getInputProps()} />
                <AttachFileIcon />
                <Typography className={classes.dropZoneText}>
                    Przenieś i upuść zdjęcie tutaj, albo wcisnij, aby przeglądać pliki.
                </Typography>
            </Box>
            <aside className={classes.thumbsContainer}>
                {base64}
            </aside>
        </section>
    );
}