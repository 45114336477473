import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';
import { GalleryCarousel } from '../../GalleryCarousel/GalleryCarousel';
import { CssDivider } from '../HomePage/CssDivider';
import { Pagination } from '@material-ui/lab';
import { galery, getImagesList, galeryImages } from '../../../helpers/galeryImages';


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: colors.white,
        paddingBottom: '20vh'
    },
    test_txt: {
        padding: 20,
        color: colors.black,
    },
    galleryContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 5
    },
    galleryItem: {
        minWidth: 300,
        height: 240,
        margin: 20,
    },
    imgItem: {
        width: '100%',
        height: 'auto'
    },
    divider: {
        margin: 10,
        width: '100%',
        border: 'solid black 1px',
    },
    paginationContainer: {
        backgroundColor: colors.white_trans2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 40,
    }
}))



export const GalleryPage = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();

    return (
        <Box className={classes.mainContainer}>
            <Typography className={classes.test_txt} variant="h3" align="center">
                {t('appBar.gallery')}
            </Typography>
            <Box className={classes.paginationContainer}>
                <Divider className={classes.divider} />
                    <Pagination count={galery.pages} page={props.galleryPage} onChange={(e,v) => props.setGalleryPage(v)}/>
                <Divider className={classes.divider} />
            </Box>
            <Box className={classes.galleryContainer}>
                <GalleryCarousel test={getImagesList(galeryImages[props.galleryPage ? props.galleryPage - 1 : 0], true)}/>
            </Box>
            <CssDivider margin />
        </Box>
    )
})