import moment from 'moment';

export const sortPersonsArray = (persons, order, orderBy) => {
    const arr = []
    persons.forEach(person => {
        if(arr.length === 0){
            arr.push(person);
            return;
        }
        let index = arr.length;

        if(orderBy === 'date_of_birth' || orderBy === 'date_of_death'){
            for (let i = 0; i < arr.length; i++) {
                if(typeof person[orderBy] !== 'string' || person[orderBy].length > 10) break;
                if(typeof arr[i][orderBy] !== 'string' || arr[i][orderBy].length > 10){
                    index = i;
                    break;
                }

                let d1 = null;
                let d2 = null;
                if(person[orderBy].length === 4){
                    d1 =  moment(`01.01.${person[orderBy]}`, "DD.MM.YYYY");
                }else{
                    d1 =  moment(person[orderBy], "DD.MM.YYYY");
                }

                if(arr[i][orderBy].length === 4){
                    d2 =  moment(`01.01.${arr[i][orderBy]}`, "DD.MM.YYYY");
                }else{
                    d2 =  moment(arr[i][orderBy], "DD.MM.YYYY");
                }
                
                if (d1 < d2) {
                    index = i;
                    break;
                }
            }
        }else{
            for (let i = 0; i < arr.length; i++) {
                if(typeof person[orderBy] !== 'string') break;
                if(typeof arr[i][orderBy] !== 'string' || person[orderBy].localeCompare(arr[i][orderBy]) === -1){
                    index = i;
                    break;
                }
            }
        }

        arr.splice(index, 0, person);
    });

    if(order === 'asc'){
        return arr;
    }else{
        return arr.reverse();
    }
}