export const colors = {
    white: '#fff',
    black: '#000',
    grass: 'rgb(126, 200, 80)',
    white_trans: 'rgba(255,255,255,0.5)',
    white_trans2: 'rgba(255,255,255,0.8)',
    black_trans: 'rgba(0,0,0,0.5)',
    black_trans2: 'rgba(0,0,0,0.9)',
    grass_trans: 'rgb(126, 200, 80, 0.5)',
    grass_trans2: 'rgb(126, 200, 80, 0.8)',
    green: 'rgb(0,128,0)',
    red: 'rgb(255, 0, 0)',
    red_trans2: 'rgb(255, 0, 0, 0.8)',

    ukraine_blue: '#005bbc',
    ukraine_yellow: '#ffd600',
}

export const effects = {
    shadow: '1px  1px 2px black, 1px -1px 2px black, -1px  1px 2px black, -1px -1px 2px black'
}