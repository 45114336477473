export const galeryImages = [
    [
        "photos/galery/001_1920_1543.jpg", "photos/galery/002_1920_1272.jpg", "photos/galery/003_1920_1272.jpg", "photos/galery/004_1920_1194.jpg", "photos/galery/005_1272_1920.jpg", 
        "photos/galery/006_1290_1920.jpg", "photos/galery/007_1920_1272.jpg", "photos/galery/008_1272_1920.jpg", "photos/galery/009_1272_1920.jpg", "photos/galery/010_1361_1920.jpg", 
        "photos/galery/011_1920_1272.jpg", "photos/galery/012_1272_1920.jpg", "photos/galery/013_1920_1272.jpg", "photos/galery/014_1272_1920.jpg", "photos/galery/015_1920_1272.jpg", 
        "photos/galery/016_1920_1272.jpg", "photos/galery/017_1920_1745.jpg", "photos/galery/018_1920_1272.jpg", "photos/galery/019_1920_1272.jpg", "photos/galery/020_1920_1272.jpg", 
        "photos/galery/021_1272_1920.jpg", "photos/galery/022_1920_1272.jpg", "photos/galery/023_1920_1272.jpg", "photos/galery/024_1272_1920.jpg", "photos/galery/025_1920_1272.jpg",
    ],
    [ 
        "photos/galery/026_1920_1272.jpg", "photos/galery/027_1272_1920.jpg", "photos/galery/028_1186_1920.jpg", "photos/galery/029_1090_1920.jpg", "photos/galery/030_1920_1272.jpg", 
        "photos/galery/031_1272_1920.jpg", "photos/galery/032_1920_1272.jpg", "photos/galery/033_1920_1448.jpg", "photos/galery/034_1272_1920.jpg", "photos/galery/035_1272_1920.jpg", 
        "photos/galery/036_1272_1920.jpg", "photos/galery/037_1272_1920.jpg", "photos/galery/038_1272_1920.jpg", "photos/galery/039_1920_1272.jpg", "photos/galery/040_1177_1920.jpg", 
        "photos/galery/041_1272_1920.jpg", "photos/galery/042_1272_1920.jpg", "photos/galery/043_1920_1272.jpg", "photos/galery/044_1272_1920.jpg", "photos/galery/045_1271_1920.jpg", 
        "photos/galery/046_1242_1920.jpg", "photos/galery/047_1272_1920.jpg", "photos/galery/048_1920_1272.jpg", "photos/galery/049_1159_1920.jpg", "photos/galery/050_1920_1272.jpg", 
    ],
    [
        "photos/galery/051_1920_1521.jpg", "photos/galery/052_1920_1379.jpg", "photos/galery/053_1272_1920.jpg", "photos/galery/054_1731_1920.jpg", "photos/galery/055_1272_1920.jpg", 
        "photos/galery/056_1920_1272.jpg", "photos/galery/057_1920_1543.jpg", "photos/galery/058_1920_1110.jpg", "photos/galery/059_1920_1272.jpg", "photos/galery/060_1272_1920.jpg", 
        "photos/galery/061_1272_1920.jpg", "photos/galery/062_1272_1920.jpg", "photos/galery/063_1272_1920.jpg", "photos/galery/064_1272_1920.jpg", "photos/galery/065_1920_1272.jpg", 
        "photos/galery/066_1129_1920.jpg", "photos/galery/067_1418_1920.jpg", "photos/galery/068_1272_1920.jpg", "photos/galery/069_1272_1920.jpg", "photos/galery/070_1920_1272.jpg", 
        "photos/galery/071_1920_1272.jpg", "photos/galery/072_1920_1516.jpg", "photos/galery/073_1920_1272.jpg", "photos/galery/074_1896_1920.jpg", "photos/galery/075_1271_1920.jpg", 
    ],
    [
        "photos/galery/076_1272_1920.jpg", "photos/galery/077_1920_1272.jpg", "photos/galery/078_1272_1920.jpg", "photos/galery/079_1920_1903.jpg", "photos/galery/080_1920_1859.jpg", 
        "photos/galery/081_1920_1272.jpg", "photos/galery/082_1272_1920.jpg", "photos/galery/083_1920_1272.jpg", "photos/galery/084_1920_1272.jpg", "photos/galery/085_1920_1404.jpg", 
        "photos/galery/086_1278_1920.jpg", "photos/galery/087_1272_1920.jpg", "photos/galery/088_1272_1920.jpg", "photos/galery/089_1920_1552.jpg", "photos/galery/090_1920_1272.jpg", 
        "photos/galery/091_1920_1272.jpg", "photos/galery/092_1272_1920.jpg", "photos/galery/093_1920_1463.jpg", "photos/galery/094_1272_1920.jpg", "photos/galery/095_1323_1920.jpg", 
        "photos/galery/096_1272_1920.jpg", "photos/galery/097_1272_1920.jpg", "photos/galery/098_1272_1920.jpg", "photos/galery/099_1920_1272.jpg", "photos/galery/100_1920_1272.jpg", 
    ],
    [
        "photos/galery/101_1272_1920.jpg", "photos/galery/102_1272_1920.jpg", "photos/galery/103_1448_1920.jpg", "photos/galery/104_1272_1920.jpg", "photos/galery/105_1920_1378.jpg", 
        "photos/galery/106_1920_1272.jpg", "photos/galery/107_1272_1920.jpg", "photos/galery/108_1920_1272.jpg", "photos/galery/109_1920_1272.jpg", "photos/galery/110_1741_1920.jpg", 
        "photos/galery/111_1920_1272.jpg", "photos/galery/112_1272_1920.jpg", "photos/galery/113_1440_1920.jpg", "photos/galery/114_1920_1272.jpg", "photos/galery/115_1272_1920.jpg", 
        "photos/galery/116_1713_1920.jpg", "photos/galery/117_1565_1920.jpg"
    ]
];

export const galery = {
    url: "https://cmentarzjanowski.com.pl/",
    pages: galeryImages.length
}

export const getImagesList = (images, withThumbnail=false) => {
    return images.map(ele => {
        let split = ele.split('_');
        split[split.length - 1] = split[split.length - 1].split('.')[0];
        const original = `${galery.url}${ele}`;
        let thumbnail = original;
        if(withThumbnail){
            thumbnail= `${galery.url}${split[0]}.jpg`;
        }
        const width = split[split.length - 2];
        const height = split[split.length - 1];
        return {original, thumbnail, width, height};
    })
}

export const getRandomGaleryImages = count => {
    const images = galeryImages.flat();
    const randomImages = [];
    const indexes = [];

    for(let i = 0; i < count; i++){
        const index = Math.floor(Math.random() * images.length);

        if(indexes.includes(index))
            continue;
        
        indexes.push(index);
        randomImages.push({
            image: images[index],
            page: Math.ceil(index / galeryImages[0].length),
            index: index - (Math.floor(index / galeryImages[0].length) * galeryImages[0].length)
        });
    }

    return getImagesList(randomImages.map(ele => ele.image), true)
        .map(
        (ele, index) => {
            ele.page = randomImages[index].page
            ele.index = randomImages[index].index
            return ele;
        }
    );
};