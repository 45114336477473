import React, { memo } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    TextField,
  } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';



const useStyles = makeStyles((theme) => ({
    logInForm: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50vw',
        margin: 'auto',
        marginTop: 50
    },
    input: {
        margin: 10
    },
    helper: {
        color: colors.red
    },
    btnContainer: {
        width: '40vw',
        margin: '20px auto'
    },
    btn: {
        margin: 10,
        padding: 10
    }
}))


export const LogIn = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const enterHandler = e => {
        const key = e.nativeEvent.key;

        if(key === 'Enter' && !disabledCondition()) {
            props.logInHandler();
        }
    }

    const disabledCondition = () =>
        props.isLoading 
        || !props.logInForm.username 
        || !props.logInForm.password 
        || props.logInForm.username === '' 
        || props.logInForm.password === ''


    return (
        <Box className={classes.logInForm} >
            <TextField
                className={classes.input} 
                id="login-username"
                required
                label="Użytkownik"
                value={props.logInForm.username}
                variant="outlined"
                onChange={props.onChangeInput}
                error={props.logInForm.username === ''}
                helperText={
                    props.logInForm.username === '' ?
                        `Pole 'Użytkownik' nie może być puste.`
                    :
                        props.error ? 
                            'Użytkownik o takiej nazwie nie istnieje lub zostało podane złe hasło.'
                        :
                            ''
                }
                placeholder="Wpisz nazwę użytkownika"
                FormHelperTextProps={{
                    className: classes.helper
                }}
                onKeyPress={e => enterHandler(e)}
            />
            <TextField
                className={classes.input}
                id="login-password"
                required
                label="Hasło"
                inputProps={{
                    type: "password"
                }}
                value={props.logInForm.password}
                variant="outlined"
                onChange={props.onChangeInput}
                error={props.logInForm.password === ''}
                helperText={
                    props.logInForm.password === '' ?
                        `Pole 'Hasło' nie może być puste.`
                    :
                        ''
                }
                placeholder="Wpisz hasło"
                FormHelperTextProps={{
                    className: classes.helper
                }}
                onKeyPress={e => enterHandler(e)}
            />
            <Box className={classes.btnContainer}>
                <Button
                    className={classes.btn}
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={props.logInHandler}
                    disabled={disabledCondition()}
                >
                    {props.isLoading ?
                            <CircularProgress />
                        : 
                            'Zaloguj'
                    }
                </Button>
            </Box>
        </Box>
    )
})