/**
 * Function that detect value failure and convert to '-'.
 * @param {String} value 
 * @returns 
 */
export const toDash = value => {
    if(value === '' || value === null || value === "[…]") return '[...]';

    return value;
}

/**
 * Function that convert long name region to short name region.
 * @param {String} regionName 
 * @returns 
 */
 export const toShortRegion = regionName => {
    if(regionName.length !== 4) return regionName;

    let shorRegionName;
    if(regionName.charAt(3) !== '0'){
        shorRegionName = `${regionName.substring(0, 2)}${regionName.charAt(3)}`

    }else{
        shorRegionName = regionName.substring(0, 2);
    }

    if(shorRegionName.charAt(0) === '0'){
        shorRegionName = shorRegionName.substring(1);
    }

    return shorRegionName;
}