import React, { memo, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Typography,
  } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AddIcon from '@material-ui/icons/Add';
import { colors } from '../../../helpers/colors';
import { getGravesIds } from '../../../fetch/graves';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    dbContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dbText: {
        width: '100%',
        margin: 15,
    },
    icon: {
        cursor: 'pointer',
    },
    formContainer: {
        border: 'solid 2px #000',
        borderRadius: 15,
        maxHeight: '70vh'
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        maxHeight: '45vh'
    },
    paginationContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 5,
        marginBottom: 15
    },
    btn: {
        margin: 10,
        display: 'flex',
    },
    btnTxt: {
        cursor: 'pointer',
        display: 'flex',
        paddingLeft: 5,
    },
    idTxt: {
        cursor: 'pointer',
        paddingLeft: 5,
        color: colors.red
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 30
    },
    row: {
        display: 'flex',
        maxWidth: '75vw',
        maxHeight: '50vh',
        flexWrap: 'wrap',
        overflow: 'auto'
    },
    loader: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap'
    },
    btn2: {
        margin: 15
    },
    skeleton: {
        margin: 5
    }
}));

export const DbRecords = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsError(false);
        setIsLoading(true);

        getGravesIds(props.auth.token)
        .then(res => {
            if(res.status < 205) 
                props.setDbList(res.data);
            else
                throw new Error('Not allowed response status.');
        })
        .catch(err => {
            setIsError(true);
            console.log(err)
        })
        .finally(() => setIsLoading(false))

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const generateSkeleton = count => {
        const loader = [];
        for(let i=0; i < count; i++) {
            loader.push(<Skeleton className={classes.skeleton} variant="rectangular" width={150} height={40} />)
        }

        return loader;
    }

    const onClickId = ele => {
        props.setTarget('edit')
        props.setSelectedDb(ele.ID_grob);
        props.setSelectedDbPersons(ele.persons)
        props.setDbForm({
            ID_grob: '',
            Rodzaj_grobu: '',
            Kategoria_grobu: '',
            Dokres_grobu: '',
            Inskrypcja: '',
            Cmentarz: '',
            osoby: []
        })
    }

    const onClickAdd = () => {
        props.setTarget('create');
        props.setSelectedDb('');
        props.setDbForm({
            ID_grob: '',
            Rodzaj_grobu: '',
            Kategoria_grobu: '',
            Dokres_grobu: '',
            Inskrypcja: '',
            Cmentarz: '',
            osoby: []
        })
    }


    return [
        <Box className={classes.dbContainer}>
            <KeyboardBackspaceIcon 
                className={classes.icon} 
                onClick={() => {
                    props.setTarget(undefined);
                    props.setDbList([])
                }}
            />
            <Typography
                className={classes.dbText}
                align="center"
                variant="h5"
            >
                Wybierz identyfikator grobu do edycji
            </Typography>
        </Box>,
        <Box className={classes.formContainer}>
            <Box className={classes.table} >
                <Box className={classes.row} >
                    
                    {
                        isLoading ? 
                            <Box className={classes.loader} >
                                {generateSkeleton(80)}
                            </Box>
                        :
                            props.dbList.map(ele => 
                                <Button 
                                    className={classes.btn} 
                                    variant="contained"
                                    onClick={() => onClickId(ele)}
                                >
                                    <AssignmentIndIcon className={classes.icon} /> 
                                    <Typography className={classes.btnTxt} >
                                        {`Id: `}
                                        <Typography className={classes.idTxt} >
                                            {ele.ID_grob}
                                        </Typography>
                                    </Typography>
                                </Button>
                            )
                    }
                    { isError && 
                        <Typography 
                            variant="h4" 
                            align="center"
                        >
                            Przepraszamy, nie mozna pobrać wyników.
                        </Typography>
                    }
                </Box>
            </Box>
        </Box>,
        <Button 
            className={classes.btn2} 
            color="primary"
            variant="contained"
            onClick={() => onClickAdd()}
            fullWidth
        >
            <AddIcon className={classes.icon} /> 
            <Typography className={classes.btnTxt} >
                Utwórz nowy wpis do bazy danych
            </Typography>
        </Button>
    ]
})
