import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CssBaseline,
  Fab,
  Box,
  Backdrop,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import bgImg from './assets/img/webp/cemetery7.webp';
import { Helmet } from "react-helmet";


import { ScrollOnTop } from './components/ScrollOnTop/ScrollOnTop';
import { AppBarTop } from './components/AppBarTop/AppBarTop';
import { colors } from './helpers/colors';
import { HistoryBread } from './components/HistoryBread/HistoryBread';
import { HomePage } from './components/Pages/HomePage/HomePage'
import { HistoryPage } from './components/Pages/HistoryPage/HistoryPage'
import { SearchPage } from './components/Pages/SearchPage/SearchPage';
import { GeoportalPage } from './components/Pages/GeoportalPage/GeoportalPage';
import { GalleryPage } from './components/Pages/GalleryPage/GalleryPage';
import { AboutPage } from './components/Pages/AboutPage/AboutPage'
import { ContactPage } from './components/Pages/ContactPage/ContactPage'
import { FlagCSS } from './components/FlagCSS/FlagCSS';
import { MobileMenu } from './components/MobileMenu/MobileMenu';
import { _breakpoints } from './helpers/breakpoints';
import { Footer } from './components/Footer/Footer';
// import { availableRegions } from './helpers/availableRegions';
import { MainLoader } from './components/MainLoader/MainLoader';
import { capitalizeFirstLetter } from './helpers/capitalizeFirstLetter';
import { initialState } from './helpers/initialState';
import { AdminPanel } from './components/Pages/AdminPanel/AdminPanel';
import { postVisits } from './fetch/visits';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.black,
  },
  bgImg: {
    position: 'fixed',
    background:`url(${bgImg})`,
    backgroundPosition: 'center',
    height: '98vh',
    width: '80vw',
    marginLeft: '20vw',
    filter: 'contrast(110%)',
    backgroundColor: colors.black,
    [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
      width: '100vw',
      marginLeft: '10vw',
    },
  },
  beforeMainContent: {
    zIndex: 1,
    display: 'flex',
  },
  mainContent: {
    position: 'relative',
    flexGrow: 1,
    zIndex: 3,
    minHeight: '100vh',
    marginTop: 50
  },
  icon: {
    backgroundColor: colors.red
  },
  adminPanelContainer: {
    position: 'fixed',
    zIndex: 10000
  },
  adminBackdropDelete: {
    position: 'fixed',
    zIndex: 10001
  },
  adminBackdropDeleteContainer: {
    backgroundColor: colors.white,
    padding: 20
  },
  upIcon: {
    position: 'fixed',
    zIndex: 1000000
  }
}))


const App = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { 
    i18n, 
    // t 
  } = useTranslation();
  // const { speak, cancel } = useSpeechSynthesis();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState('pl');
  const [path, setPath] = useState(props.path ? props.path : '/');
  const [isHamburger, setIsHamburger] = useState(true);
  const [isBackdrop, setIsBackdrop] = useState({
    state: false,
    index: undefined
  });
  const [menu, setMenu] = useState(props.menu ? props.menu : {
    isHome: true,
    isHistory: false,
    isSearch: false,
    isGeoportal: false,
    isGallery: false,
    isProject: false,
    isContact: false,
    isAdminPanel: false,
  })
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [region, setRegion] = useState('');
  const [grave, setGrave] = useState('');
  const [cemetery, setCemetery] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [galleryPage, setGalleryPage] = useState(initialState('paginationG', history));
  const [searchPage, setSearchPage] = useState(initialState('pagination', history));
  const [searchPageCount, setSearchPageCount] = useState(217);
  const clearMenu = {
    isHome: false,
    isHistory: false,
    isSearch: false,
    isGeoportal: false,
    isGallery: false,
    isProject: false,
    isContact: false,
    isAdminPanel: false
  }
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [auth, setAuth] = useState({token: ''});

  window.onpopstate = () => {
    const pathName = document.location.pathname === '/' ? '/home' : document.location.pathname;
    setPath(pathName);
    setMenu({
      ...clearMenu,
      [`is${capitalizeFirstLetter(pathName.replace('/', ''))}`]: true,
    });
  };

  window.addEventListener('message', e => {
    if(e.data === 'FS')
      setIsFullscreen(true)
    else if (e.data === 'NFS')
      setIsFullscreen(false)
  });

  useEffect(() => {
    setIsLoading(false);

    postVisits(document.referrer)
    .then()
    .catch(err => console.log(err))

    switch(history.location.pathname){
      case "/search":
        setMenu({
          ...clearMenu,
          isSearch: true
        });
        setRegion(new URLSearchParams(history.location.search).get("region"));
        setSearchPage(+new URLSearchParams(history.location.search).get("pagination"))
        setGrave(new URLSearchParams(history.location.search).get("grave"));
        setFirstName(new URLSearchParams(history.location.search).get("firstName"));
        setLastName(new URLSearchParams(history.location.search).get("lastName"));
        setCemetery(new URLSearchParams(history.location.search).get("cemetery"))
        break;
      case "/geoportal":
        setCoordinates({
          longitude: new URLSearchParams(history.location.search).get("longitude"),
          latitude: new URLSearchParams(history.location.search).get("latitude")
        });
        setMenu({
          ...clearMenu,
          isGeoportal: true
        });
      break;
      case "/gallery":
        setGalleryPage(+new URLSearchParams(history.location.search).get("paginationG"));
        setMenu({
          ...clearMenu,
          isGallery: true
        });
      break;
      default:
      break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    i18n.changeLanguage(language)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  const renderPage = () => {
    if(menu.isHome) 
      return <HomePage 
        setMenu={setMenu}
        search={{
          setFirstName: setFirstName,
          setLastName: setLastName,
          setRegion: setRegion
        }}
        setPath={setPath}
      />
    else if(menu.isHistory) return <HistoryPage />
    else if(menu.isSearch)
      return <SearchPage 
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        region={region}
        setRegion={setRegion}
        grave={grave}
        setGrave={setGrave}
        path={path}
        setPath={setPath}
        searchPage={searchPage}
        setSearchPage={setSearchPage}
        searchPageCount={searchPageCount}
        setSearchPageCount={setSearchPageCount}
        setCemetery={setCemetery}
        cemetery={cemetery}
      />
    else if(menu.isGeoportal) 
      return <GeoportalPage 
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        isFullscreen={isFullscreen}
      />
    else if(menu.isGallery)
      return <GalleryPage
        galleryPage={galleryPage}
        setGalleryPage={setGalleryPage}
      />
    else if(menu.isProject) return <AboutPage />
    else if(menu.isContact) return <ContactPage />
  }

  return isLoading ? 
    <MainLoader />
  :
    (
      <div className={classes.root}>
        <CssBaseline />

        <Box id="top" />
        <MobileMenu 
          isHamburger={isHamburger}
          path={path}
          menu={menu}
          setIsHamburger={setIsHamburger}
          setPath={setPath}
          setMenu={setMenu}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setRegion={setRegion}
          setCoordinates={setCoordinates}
          setGalleryPage={setGalleryPage}
          setGrave={setGrave}
        />
        <Box id="bgImg" className={classes.bgImg} >
          <FlagCSS />
        </Box>
        <AppBarTop
          language={language}
          setLanguage={setLanguage}
          path={path}
          setPath={setPath}
          menu={menu}
          setMenu={setMenu}
          isHamburger={isHamburger}
          setIsHamburger={setIsHamburger}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setRegion={setRegion}
          setCoordinates={setCoordinates}
          setGalleryPage={setGalleryPage}
          setGrave={setGrave}
        />
        <Box id="bgImg" className={classes.beforeMainContent} />

        <main className={classes.mainContent}>
          <Backdrop
            className={classes.adminPanelContainer}
            open={menu.isAdminPanel}
          >
            <AdminPanel 
              menu={menu}
              setMenu={setMenu}
              auth={auth}
              setAuth={setAuth}
              isBackdrop={isBackdrop}
              setIsBackdrop={setIsBackdrop}
            />
          </Backdrop>
          <HistoryBread
            isHome={menu.isHome}
            path={path}
            setPath={setPath}
            setMenu={setMenu}
          />
          <Box>
            <Helmet>
              <title>
                {`Cmentarz Janowski we Lwowie
                  ${history.location.pathname.replace('/', '') === 'home' ? 
                      `${capitalizeFirstLetter(history.location.pathname.replace('/', ''))} Page`
                    : 
                      capitalizeFirstLetter(history.location.pathname.replace('/', ''))
                  }
                `}
              </title>
            </Helmet>
            {renderPage()}
            <ScrollOnTop {...props}>
              <Fab 
                className={classes.icon} 
                size="small" 
                aria-label="scroll back to top"
              >
                <KeyboardArrowUpIcon className={classes.upIcon} />
              </Fab>
            </ScrollOnTop>
          </Box>
        </main>

        <footer id="mfooter">
          <Footer 
            path={path}
            setPath={setPath}
            menu={menu}
            setMenu={setMenu}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setRegion={setRegion}
            setCoordinates={setCoordinates}
            setGalleryPage={setGalleryPage}
            setGrave={setGrave}
            setIsHamburger={setIsHamburger}
          />
        </footer>
      </div>
    );
}

export default App;