import React, { memo, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';
import { CssDivider } from '../HomePage/CssDivider';
import { postMail } from '../../../fetch/postMail';
import { _breakpoints } from '../../../helpers/breakpoints';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: colors.white,
        paddingBottom: '20vh'
    },
    txt: {
        color: colors.black,
    },
    txtHeader: {
        marginBottom: 50
    },
    txtContent: {
        padding: 10,
        width: 'calc(90vw)',
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 50,
            width: 'calc(75vw)'
        },
    },
    formContainer: {
        padding: 10,
        width: '65vw',
        margin: '50px auto',
        display: 'flex',
        borderRadius: 15,
        flexDirection: 'column',
        border: 'solid 2px',
        [theme.breakpoints.down(_breakpoints.mobileL.size+100)]: {
            width: '90vw'
        }
    },
    fromText: {
        color: colors.black,
        width: '100%'
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        minWidth: '40vw',
    },
    inputContainer: {
        margin: 10
    },
    upperBtn: {
        margin: 10,
        marginBottom: 15
    },
    btn: {
        margin: '10px auto',
        minWidth: '20vw'
    },
    counter: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'end'
    },
    success: {
        color: colors.green
    },
    error: {
        color: colors.red
    }
}))

export const ContactPage = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [form, setForm] = useState({
        iname: '',
        isurename: '',
        iemail: '',
        ititle: '',
        ibody: ''
    });
    const [err, setErr] = useState({
        iname: false,
        isurename: false,
        iemail: false,
        ititle: false,
        ibody: false
    });
    const [titleLength, setTitleLength] = useState(0);
    const [bodyLength, setBodyLength] = useState(0)
    const [isSending, setIsSending] = useState(false);
    const [isErr, setIsErr] = useState(false);
    const [isSuc, setIsSuc] = useState(false);

    const maxTitleLength = 40;
    const maxBodyLength = 1000;

    const onChange = (e, id) => {
        const newForm = form;
        let condition = true;
        newForm[id] = e.target.value;

        if(id === 'ititle') {
            if(newForm.ititle.length > maxTitleLength)
                condition = false;
            else
                setTitleLength(newForm.ititle.length)
        }
        else if (id === 'ibody') {
            if(newForm.ibody.length > maxBodyLength)
                condition = false;
            else
                setBodyLength(newForm.ibody.length)
        }

        if (condition)
            setForm(newForm);
    }

    const handleSend = e => {
        let newErr = {};
        for(var key in form) {
            if(form[key] === "" && key !== 'isurename') {
                if(key === 'ititle' && form[key].length > maxTitleLength)
                    newErr[key] = false;
                else if(key === 'ibody' && form[key].length > maxBodyLength)
                    newErr[key] = false;
                else
                    newErr[key] = true;
            }
        }

        setErr(newErr)

        if(!Object.values(newErr).includes(true)) {
            setIsSending(false);
            setIsSuc(false);
            setIsErr(false);

            postMail(form)
            .then(res => {
                if(res.status < 300)
                    setIsSuc(true);

                setIsSending(false);
            })
            .catch(err => {
                setIsErr(true);
                setIsSending(false)
            })
        }
    }


    return (
        <Box className={classes.mainContainer}>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h3" align="center">
                Masz pytania? 
                <br/>
                <br/>
                <b>Skontaktuj się z nami.</b>
            </Typography>
            <Box className={classes.formContainer}>
                <Typography className={classes.fromText} variant="h5" align="center" >
                    Twoje pytanie
                </Typography>
                <form 
                    className={classes.formContent} 
                    noValidate 
                    autoComplete="off"
                >
                    <Box>
                        <TextField 
                            className={classes.inputContainer}
                            label="Imię *"
                            autoComplete="given-name"
                            onChange={e => onChange(e, 'iname')}
                            error = {err['iname']}
                        />
                        <TextField 
                            className={classes.inputContainer}
                            label="Nazwisko"
                            autoComplete="family-name"
                            onChange={e => onChange(e, 'isurename')}
                            error = {err['isurename']}
                        />
                    </Box>
                    <TextField 
                        className={classes.inputContainer}
                        label="Adres email *"
                        autoComplete="email"
                        onChange={e => onChange(e, 'iemail')}
                        error = {err['iemail']}
                    />
                    <TextField 
                        className={classes.inputContainer}
                        label="Temat *"
                        autoComplete="off"
                        onChange={e => onChange(e, 'ititle')}
                        error = {err['ititle']}
                        inputProps={{
                            maxlength: maxTitleLength
                        }}
                    />
                    <Typography 
                        className={classes.counter}
                        variant="capation"
                    >
                        {`${titleLength}/${maxTitleLength}`}
                    </Typography>
                    <TextField 
                        className={classes.inputContainer}
                        label="Treść *"
                        autoComplete="off"
                        onChange={e => onChange(e, 'ibody')}
                        multiline
                        rows={6}
                        maxRows={6}
                        variant="filled"
                        error = {err['ibody']}
                        inputProps={{
                            maxlength: maxBodyLength
                        }}
                    />
                    <Typography 
                        className={classes.counter}
                        variant="capation"
                    >
                        {`${bodyLength}/${maxBodyLength}`}
                    </Typography>

                    <Typography 
                        className={classes.upperBtn}
                        variant="capation" 
                        align="right"
                    >
                        * wymagane
                    </Typography>
                    <Box className={classes.upperBtn}>
                        {isSuc && 
                            <Typography 
                                className={`${classes.upperBtn} ${classes.success}`}
                                variant="capation" 
                            >
                                Twoja wiadomość została wysłana.
                            </Typography>
                        }
                        {isErr && 
                            <Typography 
                                className={`${classes.upperBtn} ${classes.error}`}
                                variant="capation"
                            >
                                Przepraszamy, Twoja wiadomość nie mogła zostać wysłana. 
                            </Typography>
                        }
                    </Box>
                    <Button
                        className={classes.btn}
                        onClick={handleSend}
                        variant="contained"
                        disabled={isSending}
                    >
                        {isSending ?
                            <CircularProgress />
                        :
                            <Typography variant="h6" align="center">
                                Wyślij
                            </Typography>
                        }
                    </Button>
                </form>
            </Box>
            <CssDivider />
        </Box>
    )
})