import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    Typography,
    Divider,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Box,
    Button,
    CircularProgress,
    Modal,
    TableSortLabel
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SaveIcon from '@material-ui/icons/Save';
import { colors } from '../../helpers/colors';
import { toDash, toShortRegion } from '../../helpers/convertValue';
import { GalleryCarousel } from '../GalleryCarousel/GalleryCarousel';
import { getGraveById } from '../../fetch/graves';
import { getImagesList } from '../../helpers/galeryImages';
import { convertGraveToCsv } from '../../helpers/csv';
import { sortPersonsArray } from '../../helpers/sort';
import { _breakpoints } from '../../helpers/breakpoints';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    icon: {
        color: colors.black,
    },
    divider: {
        margin: 10,
        width: '100%',
        border: 'solid black 1px',
    },
    spaceDivider: {
        height: 50
    },
    table: {
        minWidth: 650,
    },
    galleryContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    galleryItem: {
        minWidth: 300,
        height: 240,
        margin: 20,
    },
    imgItem: {
        width: '100%',
        height: 'auto'
    },
    iconRed: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        color: colors.red
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        padding: 10,
        borderRadius: 5,
        backgroundColor: colors.white
    },
    btn: {
        margin: 15,
    },
    btns: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            flexDirection: 'column'
        }
    },
    bold: {
        fontWeight: 900,
    }
}))

export const GraveCard = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();

    const history = useHistory();

    const [isFetch, setIsFetch] = useState(true);
    const [grave, setGrave] = useState(null);

    const [details, setDetails] = useState(null);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('first_name');

    useEffect(() => {
        getGraveById(props.grave)
            .then(res => {
                setGrave(res.data);
                setIsFetch(false);
            })
            .catch(err => {
                console.log(err);
            })

        const unblock = history.block((location, action) => {
            if(action === "POP"){
                props.setGrave(null);
            }
        })

        return () => {
            unblock();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickBack = () => {
        props.setGrave(null);
    }

    const handleClickDetails = person => {
        setDetails(person);
    }

    const handleClickSort = key => {
        if(key !== orderBy){
            setOrderBy(key);
            setOrder('asc');
        }else{
            if(order === 'asc'){
                setOrder('desc');
            }else{
                setOrder('asc');
            }
        }
    }

    const renderAttribute = (name, value) => {
        return(
            <Grid item xs={12} sm={6} md={3}>
                <Typography
                    className={classes.bold}
                >
                    {t(`search.card.${name}`)}
                </Typography>
                <Typography>
                    {value}
                </Typography>
            </Grid>
        )
    }

    const renderGrave = grave => {
        return (
            <Grid container spacing={4} alignItems='center'>
                {renderAttribute("category", toDash(grave.category ? grave.category : grave.postage))}
                {renderAttribute("inscription", toDash(grave.inscription))}
                {renderAttribute("type", toDash(grave.type))}
                {renderAttribute("region", toShortRegion(grave.id_grob.substring(0, 4)))}
            </Grid>
        )
    }

    const renderPersons = persons => {
        return(
            <TableContainer component={Paper}>
                <Table
                    className={classes.table}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "first_name"}
                                    direction={orderBy === "first_name" ? order : 'asc'}
                                    onClick={() => handleClickSort("first_name")}
                                    className={classes.bold}
                                >
                                    {t('search.card.firstName')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "last_name"}
                                    direction={orderBy === "last_name" ? order : 'asc'}
                                    onClick={() => handleClickSort("last_name")}
                                    className={classes.bold}
                                >
                                    {t('search.card.lastName')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "sex"}
                                    direction={orderBy === "sex" ? order : 'asc'}
                                    onClick={() => handleClickSort("sex")}
                                    className={classes.bold}
                                >
                                    {t('search.card.sex')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "date_of_birth"}
                                    direction={orderBy === "date_of_birth" ? order : 'asc'}
                                    onClick={() => handleClickSort("date_of_birth")}
                                    className={classes.bold}
                                >
                                    {t('search.card.dateOfBirth')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "date_of_death"}
                                    direction={orderBy === "date_of_death" ? order : 'asc'}
                                    onClick={() => handleClickSort("date_of_death")}
                                    className={classes.bold}
                                >
                                    {t('search.card.dateOfDeath')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                className={classes.bold}
                            >
                                {t('search.card.details')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortPersonsArray(persons, order, orderBy).map(person =>
                            <TableRow key={`row_${person.id_person}`}>
                                <TableCell>{toDash(person.first_name)}</TableCell>
                                <TableCell>{toDash(person.last_name)}</TableCell>
                                <TableCell>{toDash(person.sex)}</TableCell>
                                <TableCell>{toDash(person.date_of_birth)}</TableCell>
                                <TableCell>{toDash(person.date_of_death)}</TableCell>
                                <TableCell><Button variant="text" onClick={() => handleClickDetails(person)}>{t('search.card.more')}</Button></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderGalery = images => {
        return (
            <Box className={classes.galleryContainer}>
                <GalleryCarousel test={getImagesList(images)} />
            </Box>
        );
    }

    const renderLocation = position => {
        return (
            <Button
                className={classes.btn}
                variant="contained"
                href={`/geoportal?longitude=${position.longitude}&latitude=${position.latitude}`}
                startIcon={<LocationOnIcon />}
            >
                {`${t('search.card.location')}`}
            </Button>
        )
    }

    const renderSave = grave => {
        return (
            <Button
                className={classes.btn}
                variant="contained"
                endIcon={<SaveIcon />}
                onClick={() => convertGraveToCsv(grave)}
            >
                {`${t('search.card.save')}`}
            </Button>
        )
    }

    const renderDetails = () => {
        return (
            <Modal
                open={details}
                onClose={() => setDetails(null)}
            >
                <Box
                    className={classes.modal}
                >
                    {details.first_name &&
                    <Typography>
                      {`${t('search.card.firstName')}: ${toDash(details.first_name)}`}
                    </Typography>
                    }
                    {details.second_name &&
                    <Typography>
                      {`${t('search.card.secondName')}: ${toDash(details.second_name)}`}
                    </Typography>
                    }
                    {details.last_name &&
                    <Typography>
                      {`${t('search.card.lastName')}: ${toDash(details.last_name)}`}
                    </Typography>
                    }
                    {details.from_home &&
                    <Typography>
                      {`${t('search.card.fromHome')}: ${toDash(details.from_home)}`}
                    </Typography>
                    }
                    {details.date_of_birth &&
                    <Typography>
                      {`${t('search.card.dateOfBirth')}: ${toDash(details.date_of_birth)}`}
                    </Typography>
                    }
                    {details.date_of_death &&
                    <Typography>
                      {`${t('search.card.dateOfDeath')}: ${toDash(details.date_of_death)}`}
                    </Typography>
                    }
                    {details.life_length !== null &&
                    <Typography>
                      {`${t('search.card.personLife')}: ${toDash(details.life_length)}`}
                    </Typography>
                    }
                    {details.profession &&
                    <Typography>
                      {`${t('search.card.profession')}: ${toDash(details.profession)}`}
                    </Typography>
                    }
                    {details.profession_remarks &&
                    <Typography>
                      {`${t('search.card.professionRemarks')}: ${toDash(details.profession_remarks)}`}
                    </Typography>
                    }
                    {details.husband_profession &&
                    <Typography>
                      {`${t('search.card.husbandProfession')}: ${toDash(details.husband_profession)}`}
                    </Typography>
                    }
                    {details.husband_profession_remarks &&
                    <Typography>
                      {`${t('search.card.husbandProfessionRemarks')}: ${toDash(details.husband_profession_remarks)}`}
                    </Typography>
                    }
                    {details.remarks &&
                    <Typography>
                      {`${t('search.card.remarks')}: ${toDash(details.remarks)}`}
                    </Typography>
                    }
                </Box>
            </Modal>
        )
    }

    return(
        <>
            <Button
                title="Powrót"
                className={classes.icon}
                onClick={handleClickBack}
            >
                <Typography className={`${classes.iconRed}`}>
                    <ArrowBackIcon />
                    Powrót
                </Typography>
            </Button>
            {isFetch ?
                <CircularProgress />
            :
                <>
                    <Typography variant="h4">
                        {t('search.card.graveHeader')}
                    </Typography >
                    <Divider className={classes.divider} />
                    {renderGrave(grave)}
                    <Divider className={classes.spaceDivider} />
                    <Box className={classes.btns}>
                        {grave.region?.longitude && grave.region?.latitude && renderLocation({longitude: grave.region.longitude, latitude: grave.region.latitude})}
                        {renderSave(grave)}
                    </Box>
                    <Divider className={classes.spaceDivider} />
                    <Typography variant="h4">
                        {t('search.card.personsHeader')}
                    </Typography>
                    <Divider className={classes.divider} />
                    {renderPersons(grave.persons)}
                    <Divider className={classes.spaceDivider} />
                    {grave.photos.length > 0 &&
                        <>
                            <Typography variant="h4">
                            {t('search.card.galery')}
                            </Typography>
                            <Divider className={classes.divider} />
                            {renderGalery(grave.photos)}
                        </>
                    }
                    {details && renderDetails()}
                </>
            }
        </>
    )
}