import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Slide,
  Typography,
  Zoom,
  Button,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';
import homePage_img from '../../../assets/img/homePage_img.png';
import minimap from '../../../assets/img/webp/minimap.webp';
import project from '../../../assets/img/webp/project.webp';

import { BackgroundImage } from './BackgroundImage';
import { HeaderGrid } from './HeaderGrid';
import { GalleryCarousel } from '../../GalleryCarousel/GalleryCarousel';
import { CssDivider } from './CssDivider';
import { _breakpoints } from '../../../helpers/breakpoints';
import { getRandomGaleryImages } from '../../../helpers/galeryImages';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
    section: {
        margin: '50px 0',
        position: 'relative',
        textAlign: 'justify',
        zIndex: 0,
        '& h3': {
            fontSize: '2.5vw',
        },
        '& h6': {
            fontSize: '1.1vw'
        },
        [theme.breakpoints.down(_breakpoints.laptopL.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.laptopL.fontSizeMultiply * 2}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.laptopL.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.laptop.fontSizeMultiply * 2.2}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.laptop.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.tablet.fontSizeMultiply * 1.8}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.tablet.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.mobileL.fontSizeMultiply * 1.5}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.mobileL.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.mobileM.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.mobileM.fontSizeMultiply * 1.4}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.mobileM.fontSizeMultiply}vw`,
            },
        },
    },
    topSection: {
        marginTop: 15,
    },
    graveSection: {
        zIndex: 5
    },
    gallerySection: {
        zIndex: 5
    },
    mapSection: {
        textAlign: 'initial'
    },
    projectSection: {
        textAlign: 'initial'
    },
    topContainer: {
        maxWidth: '60vw',
        margin: 'auto',
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            maxWidth: '80vw'
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            maxWidth: '90vw'
        },
    },
    mainContainer: {
        padding: 20,
        paddingTop: 50,
        zIndex: 1,
        position: 'relative',
        backgroundColor: colors.white
    },
    txt: {
      color: colors.black,
      backgroundColor: 'transparent', 
      borderColor: 'transparent'
    },
    txt2: {
        color: colors.white,
        margin: 10,
        backgroundColor: 'transparent', 
        borderColor: 'transparent'
    },
    txtHeader: {
        marginBottom: 50,
    },
    txtContent: {
        padding: 10,
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
        },
    },
    textIndent: {
        textIndent: '5%',
    },
    imgContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        borderColor: 'transparent',
        backgroundColor: 'transparent'
    },
    img: {
        width: '90vw',
        height: 'auto',
        [theme.breakpoints.up('md')]: {
            width: '45vw'
        },
    },
    imgDesc: {
    },
    test_txt: {
        padding: 20,
        color: colors.black,
        margin: '25px auto',
        position: 'relative',
    },
    galleryContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: '75vw',
        minWidth: 320,
        overflowX: 'clip',
        margin: 'auto',
        position: 'relative',
        zIndex: 1,
        borderColor: 'transparent',
    },
    galleryItem: {
        minWidth: 300,
        height: 240,
        margin: 20,
    },
    imgItem: {
        width: '100%',
        height: 'auto'
    },
    textGrid: {
        marginTop: 20,
        borderColor: 'transparent',
        backgroundColor: 'transparent'
    },
    btn: {
        margin: '20px 0',
        width: '100%',
        fontSize: '1.25rem',
        textDecoration: 'underline'
    },
    minimapContainer: {
        width: '100%',
        height: '70vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background:`url(${minimap})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            marginTop: '20vw'
        },
    },
    minimap: {
        maxWidth: '30vw',
        backgroundColor: colors.black_trans2,
        display: 'flex',
        alignSelf: 'baseline',
        marginRight: '50vw',
        marginTop: '-2vh',
        padding: 20,
        flexDirection: 'column',
        borderColor: 'transparent',
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            maxWidth: '60vw',
            marginTop: '-15vw',
            marginRight: '0vw',
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            maxWidth: '80vw',
        },
    },
    projectContainer: {
        width: '100%',
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background:`url(${project})`,
        backgroundSize: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        filter: 'contrast(125%)',
        [theme.breakpoints.down(_breakpoints.laptopL.size)]: {
            backgroundSize: '65%'
        },
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            backgroundSize: '80%',
            marginTop: '20vw',
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            marginBottom: '-25vw'
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            backgroundSize: '110%',
            marginBottom: '-55vw'
        },
        [theme.breakpoints.down(_breakpoints.mobileM.size)]: {
            backgroundSize: '120%',
            marginBottom: '-70vw'
        },
    },
    project: {
        backgroundColor: colors.black_trans2,
        display: 'flex',
        alignSelf: 'baseline',
        marginTop: '-2vh',
        padding: 20,
        flexDirection: 'column',
        maxWidth: '30vw',
        margin: '0px 10px auto auto',
        borderColor: 'transparent',
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            margin: 'auto',
            marginTop: '-11vh',
            maxWidth: '50vw'
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            maxWidth: '60vw'
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            marginTop: '-2vh',
            maxWidth: '80vw'
        },
        [theme.breakpoints.down(_breakpoints.mobileM.size)]: {
            maxWidth: '90vw',
            marginTop: '-8vh',
        },
    }
}))

export const HomePage = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    const history = useHistory();


    const handleClickMore = (state) => {
        let states = {
            isHome: false,
            isHistory: false,
            isSearch: false,
            isGeoportal: false,
            isGallery: false,
            isProject: false,
            isContact: false
        };

        states[state] = true;

        const location = state.replace('is', '/').toLowerCase();
        history.push(location)
        props.setPath(location)
        props.setMenu(states);

        const anchor = document.querySelector('#top');
        if (anchor) {
          anchor.scrollIntoView(true, { behavior: 'smooth', block: 'center' });
        }
    }

    const renderContentGrid = () => 
        <Box className={classes.topContainer}>
            <Slide 
                in={true} 
                direction="right" 
                timeout={500}
            >
                <Box 
                    className={classes.textGrid}
                    component="button"
                >
                    {renderIntroText()}
                </Box>
            </Slide>
            <Zoom
                in={true} 
                direction="left" 
                timeout={1000}
            >
                <Box 
                    className={classes.imgContainer}
                    component="button"
                >
                    {renderIntroImg()}
                    <Typography 
                        className={`${classes.txt} ${classes.imgDesc}`} 
                        variant="caption" 
                        align="left"
                    >
                        Grób Hilarego Tretera na Janowskim cmentarzu we Lwowie. Rysunek niesygnowany.
                        <br />
                        Autor: Celina Dominikowska.
                        <br />
                        Data powstania: 1893.
                        <br />
                        Domena publiczna. Źródło Biblioteka Narodowa.
                    </Typography>
                </Box>
            </Zoom>
        </Box>

    const renderIntroText = () => [
        <section>
            <Typography 
                className={`${classes.txt} ${classes.txtContent} ${classes.textIndent}`} 
                variant="h6"
            >
                Cmentarz Janowski we Lwowie to jedna z największych polsko-ukraińskich nekropolii na Wschodzie, druga co do wielkości po Cmentarzu Łyczakowskim. 
                Rozległy, bo ponad <b>45-cio hektarowy</b> cmentarz znajduje się przy Rogatce Janowskiej, położonej na północny zachód od Śródmieścia. 
                Pochowano tu około dwustu tysięcy Polaków - przede wszystkim ludność cywilną, ale też wielu żołnierzy poległych w walkach o Lwów z lat 1918-1920, 
                lotników z okresu międzywojnia i ofiar II wojny światowej. Uroczyste poświęcenie cmentarza nastąpiło 29 lipca 1888 r., 
                z udziałem prezydenta Edmunda Mochnackiego i radnych miejskich. Pierwsze pogrzeby odbyły się już kilka dni później - 5 sierpnia 1888 r.
            </Typography>
            <Typography 
                className={`${classes.txt} ${classes.txtContent}`} 
                variant="h6"
            >
                Lwowska gazeta z należytą powagą informowała o tych pochówkach:
                <blockquote>
                    <i><q><b>Odtąd mogiły te mnożyć się poczną, a krzyże na nich zaznaczą wnet miejsca ostatniego spoczynku zmarłych.</b></q></i>
                </blockquote>
            </Typography>
        </section>,
        <Button 
            className={`${classes.txt} ${classes.btn}`}
            onClick={e => handleClickMore('isHistory')}
        >
            ZOBACZ PEŁNĄ HISTORIE
        </Button>
    ];

    const renderIntroImg = () => [
        <img 
            className={classes.img}
            alt='janowski-homepage-img'
            src={homePage_img}
        />
    ]


    const renderTop = () => [
        <HeaderGrid 
            text="Cmentarz Janowski we Lwowie"
            direction="right"
        />,
        renderContentGrid()
    ];

    const renderGallery = () => [
        <HeaderGrid 
            text={t('appBar.gallery')}
            direction="left"
        />,
        <Button 
            className={`${classes.txt} ${classes.btn}`}
            onClick={e => handleClickMore('isGallery')}
        >
            ZOBACZ PEŁNĄ GALERIĘ
        </Button>,
        <Box className={classes.galleryContainer} component="button">
            <GalleryCarousel 
                test={getRandomGaleryImages(6)} 
                action={
                    (ele) => {
                        history.push(`/gallery?pagination=${ele.page}`);
                        window.history.go();
                    }
                }
            />
        </Box>
    ];

    const renderMiniMapHelper = () => 
        <Box>
            <Box className={classes.minimapContainer}>
                <Box className={classes.minimap} component="button">
                    <Typography 
                        className={classes.txt2} 
                        variant="h6"
                    >
                        Dysponujemy geoportalem, w którym można zaobserwować zmiany terenowe, rozmieszczenie stref i nagrobków.
                        <br/>
                        <br/>
                    </Typography>
                    <Button 
                        className={`${classes.txt2} ${classes.btn}`}
                        onClick={e => handleClickMore('isGeoportal')}
                    >
                        PRZEJDŹ DO GEOPORTALU
                    </Button>
                </Box>
            </Box>
        </Box>;

    const renderMiniMap = () => [
        <HeaderGrid 
            text={t('appBar.geoportal')}
            direction="right"
        />,
        renderMiniMapHelper()
    ];

    const renderGraveGallery = () => [
        <HeaderGrid 
            text={t('appBar.search')}
            direction="left"
        />,
        <Button 
            className={`${classes.txt} ${classes.btn}`}
            onClick={e => handleClickMore('isSearch')}
        >
            WYSZUKAJ NAGROBEK
        </Button>,
        <Box className={classes.galleryContainer} component="button">
            <GalleryCarousel 
                test={getRandomGaleryImages(6)}
                action={
                    () => {
                        history.push('/search');
                        window.history.go();
                    }
                }
            />
        </Box>
    ];

    const renderProjectInfo = () => [
        <HeaderGrid 
            text={t('appBar.project')}
            direction="right"
        />,
        <section>
            <Box className={classes.projectContainer}>
                <Box className={classes.project} component="button">
                    <Typography 
                        className={classes.txt2} 
                        variant="h6"
                    >
                        Projekt dotyczący Cmentarza Janowskiego realizowany był w latach 2014-2017 na 
                        podstawie grantu naukowego Lapides clamabunt.
                        <br/>
                        Cmentarz Janowski we Lwowie...
                    </Typography>
                    <Button 
                        className={`${classes.txt2} ${classes.btn}`}
                        onClick={e => handleClickMore('isProject')}
                    >
                        ZOBACZ WIĘCEJ
                    </Button>
                </Box>
            </Box>
        </section>,
    ];

    
    

    return (
        <Box>
            <BackgroundImage />
            <Box className={classes.mainContainer}>
                <section 
                    id="topSection"
                    className={`${classes.section} ${classes.topSection}`}
                >
                    {renderTop()}
                </section>
                <CssDivider />
                <section
                    id="graveSection"
                    className={`${classes.section} ${classes.graveSection}`}
                >
                    {renderGraveGallery()}
                </section>
                <CssDivider right />
                <section
                    id="mapSection"
                    className={`${classes.section} ${classes.mapSection}`}
                >
                    {renderMiniMap()}
                </section>
                <CssDivider />
                <section 
                    id="gallerySection"
                    className={`${classes.section} ${classes.gallerySection}`}
                >
                    {renderGallery()}
                </section>
                <CssDivider right />
                <section
                    id="projectSection"
                    className={`${classes.section} ${classes.projectSection}`}
                >
                    {renderProjectInfo()}
                </section>
            </Box>
        </Box>
    )
})