import React, { memo } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';
import { CssDivider } from '../HomePage/CssDivider';
import { _breakpoints } from '../../../helpers/breakpoints';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: colors.white,
        paddingBottom: '20vh',
        '& h3': {
            fontSize: '2.5vw',
        },
        '& h6': {
            fontSize: '1.1vw'
        },
        [theme.breakpoints.down(_breakpoints.laptopL.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.laptopL.fontSizeMultiply * 2}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.laptopL.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.laptop.fontSizeMultiply * 2.2}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.laptop.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.tablet.fontSizeMultiply * 1.8}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.tablet.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.mobileL.fontSizeMultiply * 1.5}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.mobileL.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.mobileM.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.mobileM.fontSizeMultiply * 1.4}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.mobileM.fontSizeMultiply}vw`,
            },
        },
    },
    txt: {
        color: colors.black,
    },
    txtHeader: {
        marginBottom: 50
    },
    txtContent: {
        padding: 10,
        width: 'calc(90vw)',
        textIndent: '5%',
        textAlign: 'justify',
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            width: 'calc(75vw)'
        },
    },
}))

export const HistoryPage = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Box className={classes.mainContainer}>
            <Typography className={`${classes.txt} ${classes.txtHeader}`} variant="h3" align="center">
                Historia cmentarza Janowskiego
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                We Lwowie stolicy Królestwa Galicji i Lodomerii w okresie monarchii Habsburskiej na przełomie XIX i XX w. 
                zachodziły żywiołowe procesy społeczno-kulturowe. Z rozmachem postępował rozwój przestrzenny miasta. 
                Jednocześnie szybko zwiększała się liczba mieszkańców, a co się z tym wiązało – rosła liczba zmarłych. 
                Nic więc dziwnego, że zaczęły pojawiać się problemy związane z pochówkami. W latach osiemdziesiątych XIX w. we 
                Lwowie były czynne dwa cmentarze chrześcijańskie: Łyczakowski i Stryjski. Mimo że były wielokrotnie powiększane, 
                w szybkim tempie zapełniały się mogiłami. Ze względu na brak wolnych terenów wokół Cmentarza Stryjskiego, 
                dalsze jego powiększanie stało się niemożliwe. Ostatecznie został on zamknięty 26 października 1893 r.
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                W schyłkowym okresie funkcjonowania tego cmentarza, magistrat Lwowa rozpoczął przygotowania do utworzenia nowej nekropolii miejskiej. 
                W 1885 r. powstała komisja złożona z pracowników magistratu i rady miejskiej, która miała wskazać miejsce pod nowy cmentarz. 
                Wybór padł na niezalesione grunty leżące przy rogatce miejskiej, w końcowym odcinku ul. Janowskiej (obecnie ul. Szewczenki). 
                Było to dość odległe miejsce od śródmieścia, jednak przystępne dla północno-zachodnich dzielnic. Nie tylko dla przedmieścia 
                Krakowskiego i Halickiego, ale także i Żółkiewskiego. Nieopodal, na części gruntów Pilichowskich w 1855 r. powstał nowy Cmentarz Żydowski, 
                który w ocenie magistratu dobrze wypełniał swoją funkcję. Teren pod planowany cmentarz chrześcijański przy rogatce miejskiej, po prawej stronie 
                ul. Janowskiej w kierunku wylotowym z miasta był wolny od zabudowy. Unosił się łagodnie w kierunku Kortumowej Góry. 
                Był pochylony w kierunku miasta, nasypu kolejowego i ku błoniom Biłohoroskim. Od północnego zachodu graniczył z majątkiem Frydrychówka, 
                a od południowego wschodu usytuowany był w pewnym oddaleniu od Cmentarza Żydowskiego. Po drugiej stronie ul. Janowskiej 
                znajdowało się kilka parceli z małymi domkami, otoczonymi zielenią.
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                13 maja 1886 r. rada miejska podjęła jednogłośnie uchwałę o otwarciu gminnego cmentarza chrześcijańskiego obok rogatki Janowskiej. 
                Decyzja rady wywołała niezadowolenie mieszkańców tej części miasta. 31 maja 1886 r. kilkudziesięciu lwowiaków złożyło nawet 
                zażalenie do Namiestnictwa Galicyjskiego przeciwko wyborowi na cmentarz miejski terenu przy ul. Janowskiej. Zostało ono przez 
                Namiestnictwo oddalone. W tej sytuacji część właścicieli nieruchomości znajdujących się w pobliżu planowanego cmentarza skierowała 
                skargę do Wiednia. W konsekwencji przez dwa lata toczyła się odwoławcza procedura administracyjna. Ostatecznie po zapoznaniu się ze 
                stanem rzeczy, 22 czerwca 1888 r. ministerstwo spraw wewnętrznych w Wiedniu zdecydowało się odstąpić od postępowania w sprawie 
                cmentarza przy ul. Janowskiej, utrzymując w mocy decyzje władz Lwowa o jego utworzeniu. Wówczas władze miejskie zaczęły w szybkim 
                tempie prowadzić prace przygotowawcze do otwarcia nowej nekropolii. Wydzieliły z zasobów komunalnych teren o powierzchni 9 morgów 
                (ok. 5,2 ha), co – zgodnie z opinią komisji powołanej do przygotowania nowej nekropolii – miało na dłuższy czas wystarczyć na pochówki. 
                Jednak aby zabezpieczyć większą powierzchnie grzebalną, Urząd Miasta zakupił leżący od zachodniej strony grunt Izraela Sobela, 
                było to kolejne ok. 5 morgów (ok.3 ha) oraz trzy mniejsze prywatne parcele katastralne o łącznej powierzchni półtora morgi (ok. 1 ha). 
                Władze miejskie zakupiły dom mieszkalny z przeznaczeniem na kancelarię i mieszkanie dozorcy cmentarza (grabarza). Od ul. Janowskiej 
                wydzielono pas, na którym został urządzony plac zajazdowy dla karawanów pogrzebowych i innych pojazdów. Całość frontowej strony obsadzono 
                zielenią, w ten sposób linia grobów zaczynała się co najmniej 30 m od drogi. Pierwszy pogrzeb na Cmentarzu Janowskim odbył się 5 sierpnia 1888 r. 
                W drugiej połowie 1890 r. magistrat postawił dzwonnicę przy wejściu na cmentarz. Zamontowano na niej dzwony, które do tej pory rozbrzmiewały 
                na Cmentarzu Łyczakowskim. Na tym ostatnim ustawiono nowe dzwony wykonane przez największy w monarchii habsburskiej zakład ludwisarski 
                Petera Hilzera w St. Bernhard koło Horn. 1 maja 1897 r. na Cmentarzu Janowskim magistrat uruchomił kostnicę miejską. Na przełomie lat 1904/1905 
                została wybudowana portiernia przy bramie głównej cmentarza. W okresie międzywojennym za portiernią została wybudowana ubikacja, zaś cmentarz 
                częściowo został otoczony murem. W schyłkowym okresie międzywojennym powierzchnia cmentarza wynosiła ok. 16 ha, podzielonych na 54 pola, z około 
                115 000 tysiącami pochówków. Po drugiej wojnie światowej teren cmentarza został jeszcze powiększony. W 1962 roku, po rozwiązaniu lwowskiej gminy 
                żydowskiej, do Cmentarza Janowskiego został przyłączony Cmentarz Żydowski. Ze względu na ciągłe „dokładanie” nowych grobów pomiędzy już 
                istniejące, wkradł się chaos wewnątrz wszystkich starszych pól cmentarnych. Wiele starszych grobów, głównie z inskrypcjami polskimi, 
                zostało zlikwidowanych, a w ich miejsce powstały nowe, w których złożono zmarłych Ukraińców lub Rosjan. Na początku lat osiemdziesiątych 
                XX w. cmentarz został zamknięty. Mimo to władze zezwoliły na chowanie zmarłych w istniejących grobowcach i grobach rodzinnych. 
                Jednak nadal pojawiają się nowe groby, często w miejscach starych pochówków. Do czasów nam współczesnych teren cmentarza pomimo zagęszczenia 
                grobami i grobowcami pochodzącymi z okresu powojennego nadal zachował układ istniejących historycznych pól pochówkowych. Z dziedzińca pod 
                górę biegnie główna aleja cmentarna, od niej odchodzą boczne aleje. Większość z nich nie została uporządkowana. Na nielicznych został wylany 
                asfalt, który częściowo jest już w opłakanym stanie. Pierwotnie pozbawiony zalesienia teren cmentarny, do czasów nam współczesnych wzbogacił 
                się o naturalne samosiewy drzew i krzewów.
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Dużą grupę pochowanych na Cmentarzu Janowskim stanowią rzemieślnicy, drobni kupcy, niższej rangi urzędnicy, podurzędnicy i – jak to wtedy 
                określano – słudzy państwa, zatrudnieni w administracji państwowej i samorządowej, sądownictwie i więziennictwie. Leży tutaj wielu kolejarzy, 
                którzy w czasach zaboru austriackiego i II Rzeczypospolitej zamieszkiwali w północno-zachodniej części miasta. Wśród pochowanych znalazła się 
                grupka powstańców styczniowych, oraz żołnierzy poległych w czasie pierwszej wojny światowej. Następnie cała rzesza polskich żołnierzy z wojny 
                polsko-ukraińskiej i polsko-bolszewickiej oraz ukraińskich strzelców siczowych. Chowano tutaj emerytowanych żołnierzy armii austro-węgierskiej 
                Franciszka Józefa, zaś w okresie II Rzeczypospolitej – żołnierzy lwowskiego garnizonu Wojska Polskiego. Na janowską „Bożą rolę” na wieczny 
                spoczynek odchodzili duchowni, księża i zakonnice kościoła rzymskokatolickiego na czele z arcybiskupem Józefem Bilczewskim. W okresie międzywojennym 
                wzrosła liczba pochowanych, którzy wykonywali zawody inteligenckie. Pojawiło się więcej grobów skrywających szczątki nauczycieli i dyrektorów szkół, 
                adwokatów, dziennikarzy, lekarzy, a także bankierów i przedsiębiorców. W okresie drugiej wojny światowej chowano pomordowanych w więzieniach lwowskich 
                oraz żołnierzy walczących po stronie III Rzeszy. Wreszcie w czasach Związku Radzieckiego i  niepodległej Ukrainy, przybyłych do 
                Lwowa nowych mieszkańców, Ukraińców i Rosjan.
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Z czasem obok licznych krzyży drewnianych i metalowych, rzadziej żeliwnych lub kamiennych pojawiły się bogatsze formy upamiętniające pogrzebanych 
                takie jak grobowce i nagrobki (stele, kolumny, obeliski, płyty nagrobne) z figurami i panoramiczną płaskorzeźbą figuralną, zdjęciami 
                ceramicznymi i konfesją religijną. Niestety do czasów nam współczesnych krajobraz tej części cmentarza uległ bardzo poważnej zmianie. 
                Obecnie najstarsze pola 1–3 są pokryte nagrobkami głównie z okresu po drugiej wojnie światowej z inskrypcjami w języku ukraińskim. 
                Na przykład w północnej i zachodniej części pola 1 zachowało się jedynie kilkanaście polskich grobów z przełomu XIX i XX w. W starszej części 
                cmentarza więcej polskich grobowców i nagrobków zachowało się na polach od 4 do 14 oraz 32. Większa ilość zachowanych grobowców i nagrobków 
                znajduje się również po zachodniej stronie głównej alei, na polach 15–26 oraz 31. Na polu 37 znajdują się pozostałości po dużym przedwojennym 
                cmentarzu polskich żołnierzy poległych w obronie Lwowa i Kresów Wschodnich podczas wojny polsko-ukraińskiej i polsko-bolszewickiej. W 1990 r. 
                wzdłuż muru znajdującego się przy polach 55, 1 i 2 został wybudowany pomnik upamiętniający ofiary zbrodni dokonanych w czasie II wojny 
                światowej – ludzi wymordowanych w lwowskich więzieniach, pochowanych w zbiorowych mogiłach. Charakterystyczną cechą w krajobrazie tej 
                nekropoli są zbiorowe groby rodzinne, w których leżą szczątki pochowanych w różnym czasie. Do dziś zachowała się pewna ilość zbiorowych 
                rodzinnych grobów z charakterystycznymi owalnymi tabliczkami z inskrypcjami po polsku, głównie na krzyżach metalowych, rzadziej drewnianych.
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Najstarszym i najznamienitszym pod względem warsztatu rzeźbiarskiego na Cmentarzu Janowskim jest grobowiec rodziny Machanów. 
                Został on przeniesiony z nieczynnego Cmentarza Gródeckiego na początku XX w. i posadowiony na froncie pola 31 (vis-à-vis grobu arcybiskupa 
                Józefa Bilczewskiego). Zwieńczenie grobowca stanowi pomnik w stylu empire, przedstawiający klęczącą niewiastę żałobnicę (opłakiwaczkę). 
                Autorstwo tej jakże sugestywnej rzeźby przypisuje się Antoniemu Schimserowi, który prawdopodobnie wykonał ją w latach dwudziestych XIX w. 
                W swoim czasie restauracji pomnika dokonał Parys Filippi, mistrz rzeźby dekoracyjnej, konserwator zabytków. Do dziś na cmentarzu zachowały 
                się pomniki zdobione rzeźbą i ornamentyką, które zostały wykonane w zakładach rzeźbiarsko-kamieniarskich lwowskich rzemieślników i artystów. 
                Wśród nich wyróżniają się te, które powstały w znanym zakładzie Henryka Karola Periera (1863–1928), syna cenionego francuskiego rzeźbiarza 
                Abla Marie Periera, osiadłego we Lwowie w pierwszej połowie XIX w. Grobowce Periera można oglądać na frontach pól: 7, 31, 32. Są wykonane w 
                stylu konstruktywizmu z ornamentacją Art Deco. Interesującym obiektem sztuki sepulkralnej jest grobowiec Dzięcielowskich. Autorem projektu 
                był rzeźbiarz Grzegorz Kuźniewicz (1871–1948). Grobowiec został wykonany w 1914 r. w zakładzie kamieniarskim Ignacego Sojki, zaś interesująca 
                panoramiczna płaskorzeźba wyszła spod dłuta artysty Teobalda Orkasiewicza (1878–1933). Grobowiec Dzięcielowskich znajduje się po zachodniej 
                stronie głównej alei cmentarza, na frontowym pasie pola 31. Płaskorzeźba zatytułowana „Droga do wieczności” jest przykładem symbolizmu. 
                Stanowi alegorię popularnego w sztuce kościelnej i cmentarnej motywu bramy lub drzwi do świata zmarłych. Przedstawia sześć osób, przed 
                którymi anioł otwiera drzwi śmierci.
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Dużo grobowców i nagrobków zostało wykonanych przez zakład kamieniarski Józefa Reitera, mieszczący się przy ul. Janowskiej 81.  Wykorzystywał 
                on w tym celu głównie czerwony, rzadziej szary piaskowiec oraz czarny marmur. Zachowały się grobowce i nagrobki wykonane przez zakład Reitera 
                na polach: 5, 6, 8, 9, 14, 15, 31 i 32. Rodziny pochowanych na tej nekropolii korzystały również z usług znanego we Lwowie Zakładu Rzeźby, 
                Sztukatorstwa i Kamieniarstwa Aleksandra Króla, znajdującego się przy ul. Janowskiej 85 (A. Król posiadał filię zakładu w Przemyślu na ul. 
                Słowackiego 97). Znakomitą większość grobowców i nagrobków firma ta wykonała z czerwonego piaskowca. Do dzisiaj znajdują się one na polach: 
                1, 6, 7, 8, 13, 23, 31 i 32. Wiele prac na tej nekropolii pochodzi także z Zakładu Kamieniarsko-Rzeźbiarskiego  Władysława Korzewicza, który 
                mieścił się przy ul. Złotej 1. Grobowce i nagrobki Korzewicza możemy jeszcze znaleźć na polach: 3, 4, 7, 8, 14, 15, 23, 31 i 32. Kolejną firmą, 
                która budowała grobowce i pomniki na Cmentarzu Janowskim, były działające od 1890 r. Zakłady Marmurowe i Kamieniarskie Ludwika Tyrowicza (1861–1930), 
                znajdujące się na ul. Piekarskiej 95 i św. Pawła 6 i 8. Od 1891 r. działała także jego pracownia przy ul. Zyblikiewicza 11. Po śmierci artysty 
                działalność kamieniarsko-rzeźbiarską prowadził jego syn, Tadeusz Tyrowicz. W okresie monarchii habsburskiej i II Rzeczypospolitej Ludwik 
                Tyrowicz pracował głównie na nekropoli Łyczakowskiej, niemniej na Cmentarzu Janowskim pobudował między innymi istniejące jeszcze grobowce i 
                nagrobki na polach: 4, 9 i 13. Na ul. Bema 8 mieścił się zakład wyrobów z kamienia i pracownia rzeźbiarska Kazimierza Królika. Prawdopodobnie 
                pierwszy grobowiec, który K. Królik wzniósł na Cmentarzu Janowskim, należał do rodziny Müllerów  – został usytuowany na polu 7. W okresie 
                międzywojennym jego zakład wybudował grobowce i nagrobki na polach: 4, 7, 9 i 32. Pośród pozostałych zakładów kamieniarskich i rzeźbiarskich, 
                które wykonywały prace na Cmentarzu Janowskim należy wymienić: Ludwika Olszewskiego (na polu 32), Tadeusza Iwanowicza (na polach 17, 30), 
                Wojciecha Jabłońskiego (na polach 14, 17, 31, 32), Wojciecha Walczaka (na polach 2, 7, 13, 30), Antoniego Bojanowskiego (na polach 14, 15), 
                Ignacego Sojki ( na polu 31), Ludwika Makolondry (na polu 6).
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Cmentarz Janowski nie konkuruje pod względem sztuki sepulkralnej ze starszym o ponad sto lat Cmentarzem Łyczakowskim. 
                Nekropolia przy ul. Janowskiej powstała w czasie, gdy dokonała się już poważna zmiana stylu i mody w sztuce i architekturze grzebalnej, 
                co zaciążyło na jej pejzażu. Stare, polskie grobowce i nagrobki, które przetrwały do dziś, są w różnym stanie. Na niektórych z nich widoczne 
                są ślady po kulach z walk prowadzonych we wrześniu 1939 r. Na innych zniszczeniu uległy inskrypcje, których nie można już niestety odczytać. 
                Niektóre grobowce i nagrobki zostały pozbawione płyt z inskrypcjami, krzyży, płaskorzeźb i zdjęć ceramicznych. Są jak wyrzut sumienia. 
                Ale póki istnieją, nadal stanowią polskie dziedzictwo narodowe.
            </Typography>
            <CssDivider margin />
        </Box>
    )
})