import axios from 'axios';

export const getGraves = (params) => {
    let url = "https://cmentarzjanowski.com.pl/api/graves";

    Object.keys(params).forEach((k) => params[k] === '' && delete params[k]);

    return axios.get(url, {params});
}

export const getGraveById = (id) => {
    let url = `https://cmentarzjanowski.com.pl/api/graves/${id}`;
    return axios.get(url);
}

export const getGravesIds = (token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return axios.get('https://cmentarzjanowski.com.pl/api/admin/graves');
}

export const getGraveByIdAdmin = (token, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return axios.get(`https://cmentarzjanowski.com.pl/api/admin/graves/${id}`);
}

export const addGraveByAdmin = (token, data) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return axios.post(`https://cmentarzjanowski.com.pl/api/admin/graves`, data);
}

export const deleteGraveByIdAdmin = (token, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return axios.delete(`https://cmentarzjanowski.com.pl/api/admin/graves/${id}`);
}

export const updateGraveByIdAdmin = (token, id, data) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    delete data.photos

    return axios.put(`https://cmentarzjanowski.com.pl/api/admin/graves/${id}`, data);
}