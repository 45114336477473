import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Paper,
    CircularProgress,
    Divider,
    Box
} from '@material-ui/core'
import { SearchForm } from '../../Search/SearchForm';
import { SearchResult } from '../../Search/SearchResult';
import { GraveCard } from '../../Search/GraveCard'
import { colors } from '../../../helpers/colors';
import { getGraves } from '../../../fetch/graves';
import Pagination from '@material-ui/lab/Pagination';
import { generateSearchUrl } from '../../../helpers/urlPath';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: colors.white,
        paddingBottom: '20vh',
        minHeight: '100vh',
    },
    container: {
        backgroundColor: colors.white_trans2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 40,
    },
    divider: {
        margin: 10,
        width: '100%',
        border: 'solid black 1px',
    }
}))

export const SearchPage = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const history = useHistory();

    const [isFetch, setIsFetch] = useState(true);
    const [graves, setGraves] = useState([]);

    useEffect(() => {
        if(
            props.firstName !== '' ||
            props.lastName !== '' ||
            props.region !== '' ||
            props.cemetery !== ''
        ) 
            handleClick(new Event('click'));
        else
            search(props.searchPage);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);


        if(props.grave){
            history.push(`/search?grave=${props.grave}`);
        }
        else {
            if(props.firstName || props.lastName || props.region || props.cemetery) {
                props.setSearchPage(1);
                search(1);
                history.replace(generateSearchUrl(props.firstName, props.lastName, props.region, props.cemetery, 1, props.searchPageCount));
            }
            else {
                history.replace(generateSearchUrl(props.firstName, props.lastName, props.region, props.cemetery, props.searchPage, props.searchPageCount));
            }      
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.grave])

    const search = (page) => {
        setIsFetch(true);
        getGraves({
            first_name: props.firstName,
            last_name: props.lastName,
            region: props.region,
            cemetery: props.cemetery,
            page: page
        })
        .then(res => {
            setGraves(res.data.graves);
            props.setSearchPage(res.data.actualPage);
            props.setSearchPageCount(res.data.countPage);
            setIsFetch(false);
        })
        .catch(e => {
            console.log(e);
        })
    }

    const handleChangePage = (event, value) => {
        props.setSearchPage(value);
        search(value);
        history.replace(generateSearchUrl(props.firstName, props.lastName, props.region, props.cemetery, value, props.searchPageCount));
    }

    const handleClick = e => {
        e.preventDefault();

        const page = 1;

        props.setSearchPage(page);
        search(page)
        history.replace(generateSearchUrl(props.firstName, props.lastName, props.region, props.cemetery, page, props.searchPageCount));
    }

    return(
        <Box className={classes.mainContainer}>
        {!props.grave ?
            <Paper
                className={classes.container}
            >
                <SearchForm
                    firstName={props.firstName}
                    setFirstName={props.setFirstName}
                    lastName={props.lastName}
                    setLastName={props.setLastName}
                    region={props.region}
                    setRegion={props.setRegion}
                    cemetery={props.cemetery}
                    setCemetery={props.setCemetery}
                    search={search}
                    handleClick={handleClick}
                />
                <Divider className={classes.divider} />
                <Pagination count={props.searchPageCount} page={props.searchPage} onChange={handleChangePage}/>
                <Divider className={classes.divider} />
                {isFetch ?
                    <CircularProgress />
                :
                    <SearchResult
                        graves={graves}
                        setGrave={props.setGrave}
                        path={props.path}
                        setPath={props.setPath}
                    />
                }
            </Paper>
            :
            <Paper
                className={classes.container}
            >
                <GraveCard
                    grave={props.grave}
                    setGrave={props.setGrave}
                    path={props.path}
                    setPath={props.setPath}
                />
            </Paper>
        }
        </Box>
    )
}