import React, { memo } from 'react';
import { Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import { colors } from '../../helpers/colors';


const useStyles = makeStyles((theme) => ({
    contactContainer: {
        padding: 10
    },
    contactText: {
        color: colors.white, 
        marginTop: 10
    },
    contactLink: {
        display: 'flex', 
        alignItems: 'center', 
        cursor: 'pointer'
    },
    contactText2: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center' 
    },
    contactIcon: {
        color: colors.red, 
        marginRight: 10
    },
}))


export const FooterContact = memo((props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const handleEmail = () => window.open('mailto:cmentarzjanowski@gmail.com?subject=Pytanie');

    const goToContact = () => {
        props.setMenu({
            isHome: false,
            isHistory: false,
            isSearch: false,
            isGeoportal: false,
            isGallery: false,
            isProject: false,
            isContact: true
        });

        const anchor = document.querySelector('#top');
        if (anchor) {
            anchor.scrollIntoView(true, { behavior: 'smooth', block: 'center' });
        }
    }


    return (
        <Box className={classes.contactContainer} >
            <Typography className={classes.contactText} >
                Możesz się skontaktować z nami przez 
            </Typography>
            <Typography 
                className={`${classes.contactText} ${classes.contactLink}`}
                onClick={handleEmail} 
            >
                <MailOutlineIcon className={classes.contactIcon} />
                cmentarzjanowski@gmail.com
            </Typography>
            <Typography className={`${classes.contactText} ${classes.contactText2}`} >
                lub za pomocą
            </Typography>
            <Typography 
                className={`${classes.contactText} ${classes.contactLink}`}
                onClick={goToContact} 
            >
                <ContactMailOutlinedIcon className={classes.contactIcon} />
                formularza kontaktowego
            </Typography>
        </Box>               
    )
})