import React, { memo } from 'react';
import {
    Box,
    Button
  } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { _breakpoints } from '../../../helpers/breakpoints';




const useStyles = makeStyles((theme) => ({
    btnContainer2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    btn: {
        margin: 10,
        padding: 10
    },
    btnContainerOptions: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        marginTop: 15,
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            flexDirection: 'column'
        }
    },
}));

export const MainControl = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return [
        <Box className={classes.btnContainer2} >
            <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={props.logOutHandler}
            >
                Wyloguj
            </Button>
        </Box>,
        <Box className={classes.btnContainerOptions} >
            <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={() => props.setTarget('db')}
            >
                Edycja bazy danych
            </Button>
            <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={() => props.setTarget('stats')}
            >
                Statystyki serwisu
            </Button>
        </Box>
    ]
})
