import React, { memo, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    TextField,
    Typography,
    Button,
    // IconButton,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
  } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { colors } from '../../../helpers/colors';
// import { getRandomGaleryImages } from '../../../helpers/galeryImages';
import { DropZonePreviews } from './DropZonePreviews';
import { addGraveByAdmin, deleteGraveByIdAdmin, getGraveByIdAdmin, updateGraveByIdAdmin } from '../../../fetch/graves';


const useStyles = makeStyles((theme) => ({
    dbContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dbText: {
        width: '100%',
        margin: 15,
    },
    icon: {
        cursor: 'pointer',
    },
    formContainer: {
        border: 'solid 2px #000',
        borderRadius: 15,
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        maxHeight: '50vh'
    },
    paginationContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 5,
        marginBottom: 15
    },
    btn: {
        margin: 10,
        display: 'flex',
    },
    btnTxt: {
        cursor: 'pointer',
        display: 'flex',
        paddingLeft: 5,
    },
    idTxt: {
        cursor: 'pointer',
        paddingLeft: 5,
        color: colors.red
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 30
    },
    row: {
        display: 'flex',
        maxWidth: '75vw',
        maxHeight: '50vh',
        flexWrap: 'wrap',
        overflow: 'auto'
    },
    input: {
        margin: 10
    },
    accordion: {
        width: '100%',
        marginTop: 10
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    red: {
        color: colors.red
    },
    green: {
        color: colors.black,
        display: 'flex',
        alignItems: 'center'
    },
    galleryContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 5
    },
    btn2: {
        margin: 15
    },
    cssLabel: {
        color: colors.red
    },
    deleteIcon: {
        marginRight: 15
    },
    icon2: {
        color: colors.red
    },
    adminBackdropDelete: {
    },
    backdropLoader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '15vw',
        minHeight: '15vw'
    }
}));


export const DbForm = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);


    const [imgs, setImgs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const labelChecker = (key) => {
        if(key.includes('grob') && !key.includes('grobu')) {
            if(key.includes('groby')) 
                return key.replace('groby', 'grobu')
            if(key.includes('grobuy')) 
                return key.replace('grobuy', 'grobu')
            return key.replace('grob', 'grobu')
        }    
        if(key.includes('Cemetery')) 
            return key.replace('Cemetery', 'Cmentarz')
        return key
    }

    const mainRequired = ele => ele !== 'Kategoria_grobu' && ele !== 'Dokres_grobu';

    const personRequired = ele => 
        ele === 'ID_osoba'
        || ele === 'Kat_data_ur'
        || ele === 'Kat_data_zg'
        || ele === 'Kat_dlug_zycia'

    const detailRequired = ele => 
        ele === 'Soc_Prof'
        || ele === 'SocProfMaz'
        || ele === 'ZonaWdowa'
        || ele === 'Imie'
        || ele === 'Nazwisko'
        || ele === 'Kat_DePrimo'

    const checkUID = id => {
        if(props.initId !== undefined)
            return props.dbList.findIndex(_id => _id.ID_grob === id && id !== props.initId) !== -1;
        return props.dbList.findIndex(_id => _id.ID_grob === id) !== -1;
    }

    const checkPersonUID = id => {
        if(props.initPersonId.length) 
            return props.dbList.findIndex(_id => _id.persons.includes(id) && !props.initPersonId.includes(id)) !== -1
        return props.dbList.findIndex(_id => _id.persons.includes(id)) !== -1
    }

    const mainHelperText = (checkIdCondition, elementFromState, ele) => {
        if(mainRequired(ele) && elementFromState === '')
            return `Pole '${labelChecker(ele.split('_').join(' '))}' jest wymagane!`
        else if(checkIdCondition)
            return 'Grób o takim ID istnieje, proszę zmienić ID.'
        else
            return ''
    }

    const personHelperText = (_field, val, checkIdCondition) => {
        if(personRequired(_field) && val === '')
            return `Pole '${labelChecker(_field.split('_').join(' '))}' jest wymagane!`
        else if(checkIdCondition)
            return 'Osoba o takim ID jest przydzielona do innego grobu, proszę zmienić ID.'
        else
            return ''
    }

    const detailHelperText = (_detail, val) => {
        if(detailRequired(_detail) && val === '')
            return `Pole '${labelChecker(_detail.split('_').join(' '))}' jest wymagane!`
        else
            return ''
    }

    const onChangeMain = (e, ele) => {
        const val = e.target.value;
        props.setDbForm({
            ...props.dbForm,
            [ele]: val
        })
    }

    const onChangePerson = (e, ele, index) => {
        const val = e.target.value;
        const newValue = {
            ...props.dbForm.osoby[index],
            [ele]: val
        }

        const newList = props.dbForm.osoby;
        newList[index] = newValue;

        props.setDbForm({
            ...props.dbForm,
            osoby: newList
        })
    }

    const onChangeDetail = (e, selector, field, personIndex, detailIndex) => {
        const val = e.target.value;
        const newList = props.dbForm.osoby;
        const oldList = newList[personIndex][field];
        const newValue = {
            ...oldList[detailIndex],
            [selector]: val
        }
        
        oldList[detailIndex] = newValue;
        newList[personIndex][field] = oldList;

        props.setDbForm({
            ...props.dbForm,
            osoby: newList
        })
    }

    const renderMainInputs = () =>
        Object
        .keys(props.dbForm)
        .filter(ele => ele !== 'osoby')
        .map(ele => {
            const elementFromState = props.dbForm[ele];
            const checkIdCondition = ele === 'ID_grob' && checkUID(elementFromState);
            if(ele === 'images' || ele === 'photos') 
                return <></>

            return <TextField 
                className={classes.input}
                label={labelChecker(ele.split('_').join(' '))}
                value={elementFromState}
                onChange={e => onChangeMain(e, ele)}
                required={mainRequired(ele)}
                error={(mainRequired(ele) && elementFromState === '') || checkIdCondition}
                helperText={mainHelperText(checkIdCondition, elementFromState, ele)}
                fullWidth
            />
        });

    const renderAccordion = (title, content) => 
        <Accordion className={classes.accordion} fullWidth>
            <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.green} />} >
                <Typography className={classes.green}>
                    {typeof title === 'function' ? title() : title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {content()}
            </AccordionDetails>
        </Accordion>

    const renderButton = (title, action) =>
        <Button 
            className={classes.btn2} 
            variant="contained"
            onClick={action}
        >
            <AddIcon className={classes.icon} /> 
            <Typography className={classes.btnTxt} >
                {title}
            </Typography>
        </Button>

    const renderPersons = () => {
        return [
            renderButton(
                'Dodaj osobę',
                () => props.setDbForm({
                    ...props.dbForm,
                    osoby: [
                        ...props.dbForm.osoby,
                        {
                            ID_osoba: '',
                            Charakter_groby: '',
                            CzyOsoby: '',
                            Nazwisko: '',
                            Imie: '',
                            Plec: '',
                            Data_ur: '',
                            Data_zg: '',
                            Kat_data_ur: '',
                            Kat_data_zg: '',
                            Dlug_zycia: '',
                            Kat_dlug_zycia: '',
                            socprof: [],
                            socprofmaz: [],
                            osobyimie2: [],
                            dedomovoto: [],
                            Uwagi: ''
                        },
                    ]
                })
            ),
            props.dbForm.osoby.map((_person, personIndex) => 
                renderAccordion(
                    () => {
                        return <b>
                                {`${_person.Imie !== '' ? _person.Imie : '[...]'} ${_person.Nazwisko !== '' ? _person.Nazwisko : '[...]'}`}
                            </b>
                    },
                    () => renderOthers(_person, personIndex)
                )
            )
        ]
    }

    const renderOthers = (_person, personIndex) => {
        return [
            <Button
                className={classes.deleteIcon}
                onClick={e => {
                    e.preventDefault();
                    const persons = props.dbForm.osoby;
                    persons.splice(personIndex, 1);

                    props.setDbForm({
                        ...props.dbForm,
                        osoby: persons
                    })
                }}
            >
                <DeleteForeverIcon className={classes.icon2} />
                <Typography className={classes.icon2}>
                    Usuń osobę
                </Typography>
            </Button>,
            Object.keys(_person).map((_field, fieldIndex) => {
                if(
                    _field === 'socprof'
                    || _field === 'socprofmaz'
                    || _field === 'osobyimie2'
                    || _field === 'dedomovoto'
                )
                    return renderAccordion(
                        _field,
                        () => {
                            return [
                                renderButton(
                                    `Dodaj ${_field}`,
                                    () => {
                                        const persons = props.dbForm.osoby;
                                        let arr = [];
                                        let newValue = {};
                                        switch (_field) {
                                            case 'socprof':
                                                arr = persons[personIndex].socprof;
                                                newValue = {
                                                    Soc_Prof: '',
                                                    Uwagi: '',
                                                }

                                                arr.push(newValue)

                                                persons[personIndex] = {
                                                    ...persons[personIndex],
                                                    socprof: arr
                                                }
                                                break;

                                            case 'socprofmaz':
                                                arr = persons[personIndex].socprofmaz;
                                                newValue = {
                                                    SocProfMaz: '',
                                                    ZonaWdowa: '',
                                                    Uwagi: '',
                                                }

                                                arr.push(newValue)

                                                persons[personIndex] = {
                                                    ...persons[personIndex],
                                                    socprofmaz: arr
                                                }
                                                break;

                                            case 'osobyimie2':
                                                arr = persons[personIndex].osobyimie2;
                                                newValue = {
                                                    Imie: ''
                                                }

                                                arr.push(newValue)

                                                persons[personIndex] = {
                                                    ...persons[personIndex],
                                                    osobyimie2: arr
                                                }
                                                break;

                                            case 'dedomovoto':
                                                arr = persons[personIndex].dedomovoto;
                                                newValue = {
                                                    Nazwisko: '',
                                                    Kat_DePrimo: '',
                                                    Charakterystyka: ''
                                                }

                                                arr.push(newValue)

                                                persons[personIndex] = {
                                                    ...persons[personIndex],
                                                    dedomovoto: arr
                                                }
                                                break;

                                            default:
                                                //no default
                                                break;
                                        }

                                        props.setDbForm({
                                            ...props.dbForm,
                                            osoby: persons
                                        })
                                    }
                                ),
                                _person[_field] instanceof Array && _person[_field].map((_details, detailsIndex) => {
                                    const _keys = Object.keys(_details);

                                    return renderAccordion(
                                        _details[_keys[0]] !== '' ? _details[_keys[0]] : '[...]',
                                        () => generateOthers(_keys, _person, _field, personIndex, detailsIndex)
                                    )
                                })
                            ]
                        }
                    )
                else {
                    const val = _person[_field];
                    if(_field === 'ID_osoba') {
                        const checkIdCondition = checkPersonUID(val);

                        return <TextField 
                            className={classes.input}
                            label={labelChecker(_field.split('_').join(' '))}
                            value={val}
                            onChange={e => onChangePerson(e, _field, personIndex)}
                            required={personRequired(_field)}
                            error={(personRequired(_field) && val === '') || checkIdCondition}
                            helperText={personHelperText(_field, val, checkIdCondition)}
                            fullWidth
                        />
                    }
                    return <TextField 
                        type={_field === "Dlug_zycia" ? "number" : "text"}
                        className={classes.input}
                        label={labelChecker(_field.split('_').join(' '))}
                        value={val}
                        onChange={e => onChangePerson(e, _field, personIndex)}
                        required={personRequired(_field)}
                        error={personRequired(_field) && val === ''}
                        helperText={personHelperText(_field, val)}
                        fullWidth
                    />
                }
            })
        ]}

    const generateOthers = (_keys, _person, _field, personIndex, detailsIndex) => {
        return [
            <Button
                className={classes.deleteIcon}
                onClick={e => {
                    e.preventDefault();
                    const persons = props.dbForm.osoby;
                    persons[personIndex][_field].splice(detailsIndex, 1)

                    props.setDbForm({
                        ...props.dbForm,
                        osoby: persons
                    })
                }}
            >
                <DeleteForeverIcon className={classes.icon2} />
                <Typography className={classes.icon2}>
                    {`Usuń ${_field}`}
                </Typography>
            </Button>,
            _keys.map((_detail, detailIndex) => {
                const val = _person[_field][detailsIndex][_detail];
                return <TextField 
                    className={classes.input}
                    label={labelChecker(_detail.split('_').join(' '))}
                    value={val}
                    onChange={e => onChangeDetail(e, _detail, _field, personIndex, detailsIndex)}
                    required={detailRequired(_detail)}
                    error={detailRequired(_detail) && val === ''}
                    helperText={detailHelperText(_detail, val)}
                    fullWidth
                />
            })
        ]
    }


    useEffect(() => {
        if(props.target === 'edit' && props.initId !== undefined) {
            setIsError(false);
            setIsLoading(true);

            getGraveByIdAdmin(props.auth.token, props.initId)
            .then(res => {
                if(res.status < 205) {
                    const response = res.data;
                    setImgs(response.images);

                    if(response.osoby === '')
                        response.osoby = [];
                    if(response.osoby instanceof Array) {
                        response.osoby.map(_osoba => {
                            const osoba = _osoba;
                            if(_osoba.dedomovoto === '')
                                osoba.dedomovoto = [];
                            else if(_osoba.osobyimie2 === '')
                                osoba.osobyimie2 = [];
                            else if(_osoba.socprof === '')
                                osoba.socprof = [];
                            else if(_osoba.socprofmaz === '')
                                osoba.socprofmaz = [];
                            return osoba
                        })
                    }

                    props.setDbForm(response);
                }
            })
            .catch(err => {
                setIsError(true);
                console.log(err);
            })
            .finally(() => setIsLoading(false));
        }
        
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buttonCondition = () => {
        const _arr = [];
        
        //eslint-disable-next-line array-callback-return
        const mainCondition = Object.keys(props.dbForm).filter(ele => {
            const elementFromState = props.dbForm[ele];
            if(mainRequired(ele)) {
                if(elementFromState === '')
                    return ele
                if(ele === 'ID_grob'&& checkUID(elementFromState))
                    return ele
            }
        })
        
        props.dbForm.osoby.forEach(person => {
            //eslint-disable-next-line array-callback-return
            Object.keys(person).forEach(ele => {
                const elementFromState = person[ele];
                if(personRequired(ele)) {
                    if( elementFromState === '')
                        _arr.push(ele)
                    if(ele === 'ID_osoba' && checkPersonUID(elementFromState))
                        _arr.push(ele)
                }
                else if(
                    (ele === 'socprof'
                    || ele === 'socprofmaz'
                    || ele === 'osobyimie2'
                    || ele === 'dedomovoto')
                    && person[ele].length
                ) {
                    person[ele].forEach(detail => _arr.push(...Object.keys(detail).filter(ele2 => detailRequired(ele2) && detail[ele2] === '')))
                }
            })
        });

        return [...mainCondition, ..._arr];
    }

    return [
        <Box className={classes.dbContainer}>
            <KeyboardBackspaceIcon 
                className={classes.icon} 
                onClick={() => {
                    props.setTarget('db')
                    props.setDbList([])
                }}
            />
            <Typography
                className={classes.dbText}
                align="center"
                variant="h5"
            >
                {`${props.target === 'edit' ? 'Edycja' : 'Tworzenie'} rekordu z ID: `}<b className={classes.red}> {props.dbForm.ID_grob}</b>
            </Typography>
            {
                props.setIsBackdrop &&
                    <Button
                        className={classes.deleteIcon}
                        onClick={() => props.setIsBackdrop({state: true, index: props.initId})}
                    >
                        <DeleteForeverIcon className={classes.icon2} />
                        <Typography className={classes.icon2}>
                            {`Usuń`}
                        </Typography>
                    </Button>
            }
        </Box>,
        isError && 
            <Typography
                className={classes.dbText}
                align="center"
                variant="h6"
            >
                {`Nie udało się wykonać operacji ${props.target === 'edit' ? 'edcji' : 'tworzenia'}, spróbuj ponownie.`}
            </Typography>,
        <Box className={classes.formContainer}>
            <Box className={classes.table} >
                {isLoading ? 
                        <CircularProgress />
                    :
                        <Box className={classes.row} >
                            {renderMainInputs()}
                            {
                                renderAccordion(
                                    'Osoby',
                                    () => renderPersons()
                                )
                            }
                            {
                                renderAccordion(
                                    'Zdjęcia',
                                    () => {
                                        return <Box className={classes.dropZone} >
                                            <DropZonePreviews images={imgs} setImgs={setImgs} />
                                        </Box>
                                    }
                                )
                            }
                        </Box>
                }
            </Box>
        </Box>,
        <Button 
            className={classes.btn2} 
            variant="contained"
            color="primary"
            onClick={() => {
                const arr = buttonCondition();

                if(props.target === 'create' && !arr.length) {
                    setIsError(false);
                    setIsLoading(true);
                    addGraveByAdmin(props.auth.token, {...props.dbForm, images: imgs})
                    .then(res => {
                        if(res.status < 205) 
                            console.log(res.status)
                        else 
                            throw new Error('not allowed method')
                    })
                    .catch(err => {
                        setIsError(true)
                        console.log(err)
                    })
                    .finally(() => {
                        setIsLoading(false)
                        props.setTarget('db');
                        props.setDbList([]);
                    })
                }
                else if(props.target === 'edit' && !arr.length) {
                    setIsError(false);
                    setIsLoading(true);
                    updateGraveByIdAdmin(props.auth.token, props.initId, {...props.dbForm, images: imgs})
                    .then(res => {
                        if(res.status < 205) 
                            console.log(res.status)
                        else 
                            throw new Error('not allowed method')
                    })
                    .catch(err => {
                        setIsError(true)
                        console.log(err)
                    })
                    .finally(() => {
                        setIsLoading(false)
                        props.setTarget('db');
                        props.setDbList([]);
                    })
                }
            }}
            fullWidth
            disabled={buttonCondition().length || isLoading}
        >
            <AddIcon className={classes.icon} /> 
            {isLoading ? 
                    <CircularProgress />
                :
                    <Typography className={classes.btnTxt} >
                        {props.target === 'create' && 'Dodaj do bazy danych'}
                        {props.target === 'edit' && 'Zapisz w bazie danych'}
                    </Typography>
            }
        </Button>,
        props.isBackdrop && <Dialog
            className={classes.adminBackdropDelete}
            open={props.isBackdrop.state}
            onClose={() => props.setIsBackdrop({state: false, index: undefined})}
        >
            {isLoading ? 
                    <Box className={classes.backdropLoader} >
                        <CircularProgress />
                    </Box>
                :
                    [
                        <DialogTitle>
                            {"Czy na pewno chcesz usunąc wpis do bazy danych?"}
                        </DialogTitle>,
                        <DialogContent>
                            <DialogContentText>
                                Ta akcja spowoduje trwałe usunięcie wpisu z bazy danych.
                            </DialogContentText>
                        </DialogContent>,
                        <DialogActions>
                            <Button onClick={() => props.setIsBackdrop({state: false, index: undefined})}>
                                Nie
                            </Button>
                            <Button
                                className={classes.icon2}
                                onClick={() => {
                                    setIsError(false);
                                    setIsLoading(true);

                                    deleteGraveByIdAdmin(props.auth.token, props.isBackdrop.index)
                                    .then(res => {
                                        if(res.status < 205)
                                            console.log(res.status)
                                        else
                                            throw new Error('not allowed')
                                    })
                                    .catch(err => {
                                        setIsError(true);
                                        console.log(err)
                                    })
                                    .finally(() => {
                                        setIsLoading(false);
                                        props.setIsBackdrop({state: false, index: undefined});
                                        props.setTarget('db');
                                        props.setDbList([]);
                                    });
                                }} 
                                autoFocus
                            >
                                Tak
                            </Button>
                        </DialogActions>
                    ]
            }
        </Dialog>
    ]
})
