import React, { memo } from 'react';
import { Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../helpers/colors';

const useStyles = makeStyles((theme) => ({
    main: {
        width: '20vw', 
        height: '100vh', 
        backgroundColor: colors.black,
        marginLeft: '-20vw',
    },
    container: {
        width: '100%',
        height: '100vh'
    },
    flagContainer: {
        transform: "rotate(75deg)",
        height: '10vh',
        filter: 'blur(1px) brightness(85%)',
    },
    flag: {
        width: '500vw',
        marginLeft: -10,
        height: 12
    },
    flagWhite: {
        backgroundColor: colors.white
    },
    flagRed: {
        backgroundColor: colors.red
    },
    flagTransparent: {
        backgroundColor: 'transparent',
        height: 0.25
    },
    flagYellow: {
        backgroundColor: colors.ukraine_yellow
    },
    flagBlue: {
        backgroundColor: colors.ukraine_blue
    }
}))

export const FlagCSS = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Box className={classes.main}>
          <Box className={classes.container}>
            <Box className={classes.flagContainer}>
              <Box className={`${classes.flag} ${classes.flagWhite}`} />
              <Box className={`${classes.flag} ${classes.flagRed}`} />
              <Box className={`${classes.flag} ${classes.flagTransparent}`} />
              <Box className={`${classes.flag} ${classes.flagBlue}`} />
              <Box className={`${classes.flag} ${classes.flagYellow}`} />
            </Box>
          </Box>
        </Box>
    )
})