import React, { memo } from 'react';
import { 
    Box,
    Slide,
    Button,
    Typography
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors, effects } from '../../../helpers/colors';
import { goToSection } from '../../../helpers/goToSection';
import { _breakpoints } from '../../../helpers/breakpoints';


const useStyles = makeStyles((theme) => ({
    onImg:{
        display: 'flex',
        height: '90vh',
        backgroundColor: 'transparent',
        [theme.breakpoints.down(_breakpoints.laptopL.size)]: {
            height: '75vh',
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            height: '77vh',
        },
    },
    onImgText: {
        display: 'flex',
        width: '70%',
        height: '40vh',
        marginTop: '15vh',
        marginLeft: '15vw',
        padding: 20,
        paddingLeft: 25,
        flexDirection: 'column',
        borderColor: 'transparent',
        '& *': {
            marginBottom: 20
        },
        [theme.breakpoints.down(_breakpoints.laptopL.size)]: {
            height: '35vh'
        },
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            height: '32vh'
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            height: '35vh',
            width: '80%',
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            height: '37vh',
            '& *': {
                marginBottom: 10
            },
            width: '95%',
            marginTop: '30vh',
            marginLeft: '6vw',
        },
        [theme.breakpoints.down(_breakpoints.mobileM.size)]: {
            fontSize: `${_breakpoints.mobileM.fontSizeMultiply + 0.1}vw`,
            height: '42vh',
            width: '95%',
            marginTop: '16vh',
        }
    },
    onImgBtn: {
        width: '30vw',
        textDecoration: 'underline',
        fontSize: '1.1vw',
        [theme.breakpoints.down(_breakpoints.laptopL.size)]: {
            fontSize: `${_breakpoints.laptopL.fontSizeMultiply + 0.1}vw`,
        },
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            fontSize: `${_breakpoints.laptop.fontSizeMultiply + 0.1}vw`,
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            fontSize: `${_breakpoints.tablet.fontSizeMultiply + 0.1}vw`,
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            fontSize: `${_breakpoints.mobileL.fontSizeMultiply + 0.1}vw`,
            width: '35vw',
        },
        [theme.breakpoints.down(_breakpoints.mobileM.size)]: {
            fontSize: `${_breakpoints.mobileM.fontSizeMultiply + 0.1}vw`,
            width: '40vw',
        }
    },
    txt: {
        color: colors.white,
        textShadow: effects.shadow,
        backgroundColor: 'transparent',
        borderColor: 'transparent'
    },
    txtHeader: {
        width: '100%'
    },
    txtContent: {
        width: '35%',
        fontSize: '1vw',
        [theme.breakpoints.down(_breakpoints.laptopL.size)]: {
            fontSize: `${_breakpoints.laptopL.fontSizeMultiply}vw`,
            width: '50%',
        },
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            fontSize: `${_breakpoints.laptop.fontSizeMultiply}vw`,
            width: '65%',
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            fontSize: `${_breakpoints.tablet.fontSizeMultiply}vw`,
            width: '80%',
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            fontSize: `${_breakpoints.mobileL.fontSizeMultiply}vw`,
            width: '90%',
        },
        [theme.breakpoints.down(_breakpoints.mobileM.size)]: {
            fontSize: `${_breakpoints.mobileM.fontSizeMultiply}vw`,
        }
    },
}))

export const BackgroundImage = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const link = goToSection('#topSection');

    const handleClick = () => {
        link.click();
        window.history.pushState("", "", '/home');
    }

    return (
        <Box className={classes.onImg}>
            <Slide 
                in={true} 
                direction="right"
            >
                <Box 
                    id="onImgText"
                    className={classes.onImgText}
                    component="button"
                >
                    <Typography 
                        className={`${classes.txt} ${classes.textHeader}`} 
                        variant="h4"
                    >
                        <b>Cmentarz Janowski we Lwowie</b>
                    </Typography>
                    <Typography 
                        className={`${classes.txt} ${classes.txtContent}`} 
                        component="button"
                        align="left"
                    >
                        Cmentarz Janowski we Lwowie to jedna z największych polsko-ukraińskich nekropolii na Wschodzie, druga co do wielkości po Cmentarzu Łyczakowskim... 
                    </Typography>
                    <Button 
                        className={`${classes.txt} ${classes.onImgBtn}`}
                        onClick={handleClick}
                    >
                        CZYTAJ DALEJ
                    </Button>
                </Box>
            </Slide>
        </Box>
    )
})
