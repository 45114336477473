/**
 * Function that generates link for go to section
 * @param {String} href - href to section
 * @returns 
 */
export const goToSection = href => {
    const link = document.createElement('a');
    link.setAttribute('href', href);

    return link
}