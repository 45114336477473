import React, { memo } from 'react';
import { Box, Divider } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import logo from '../../assets/img/logo_cj.png';
import logoweb from '../../assets/img/webp/logo_cj.webp'
import { colors } from '../../helpers/colors';
import { ToolbarMenu } from '../ToolbarMenu/ToolbarMenu';
import { FooterContact } from './FooterContact';
import { Copyrights } from './Copyrights';


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        zIndex: 3,
        width: '100%',
        marginTop: '-20vh',
        display: 'flex',
        flexDirection: 'row'
    },
    container: {
        position: 'relative',
        width: 'calc(100% + 5px)',
        marginTop: '20vh',
        backgroundColor: colors.black,
        marginLeft: -5,
        zIndex: 0,
        padding: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    contentContainer: {
        margin: 20,
        marginTop: 5,
        padding: 20,
        paddingTop: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down(861)]: {
            flexDirection: 'column',
            '& div': {
                margin: 10
            }
        }
    },
    menuContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        maxHeight: 120,
        padding: 20,
        [theme.breakpoints.down(861)]: {
            alignSelf: 'center'
        }
    },
    divider: {
        backgroundColor: colors.red,
        width: '100%',
    },
}))


export const Footer = memo(({
    path, 
    setPath, 
    menu, 
    setMenu,
    setFirstName,
    setLastName,
    setRegion,
    setCoordinates,
    setGalleryPage,
    setGrave,
    setIsHamburger
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);


    return (
        <Box className={classes.mainContainer} >
            <Box className={classes.container} >
                <Box className={classes.contentContainer} >
                    <FooterContact 
                        setMenu={setMenu}
                    />
                    <Box className={classes.menuContainer}>
                        <ToolbarMenu 
                            path={path}
                            setPath={setPath}
                            menu={menu}
                            setMenu={setMenu}
                            setFirstName={setFirstName}
                            setLastName={setLastName}
                            setRegion={setRegion}
                            setCoordinates={setCoordinates}
                            setGalleryPage={setGalleryPage}
                            setGrave={setGrave}
                            isFooter  
                            type="FooterMenu"
                            setIsHamburger={setIsHamburger}
                        />
                    </Box>
                    <picture>
                        <source alt="logo-footer-small" srcset={logoweb} type="image/webp" />
                        <img 
                            alt="logo-footer"
                            className={classes.logo}
                            src={logo}
                        />
                    </picture>
                </Box>
                <Divider className={classes.divider} />
                <Copyrights />
            </Box>
        </Box>
    )
})