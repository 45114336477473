import React from 'react';
import { Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';
import { _breakpoints } from '../../../helpers/breakpoints';


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '98%',
        zIndex: 0,
    },
    rect: {
        backgroundColor: colors.red_trans2,
        height: '3vh',
        width: '10vw',
        borderRadius: '2vh',
    },
    rightToLeft: {
        transform: 'rotate(45deg)',
    },
    leftToRight: {
        transform: 'rotate(135deg)',
    },
    end: {
        justifyContent: 'end',
    },
    start: {
        justifyContent: 'start',
    },
    margin1: {
        margin: '150px 0',
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            margin: '100px 0'
        }
    },
    margin2: {
        margin: '20px 0'
    }
}))


export const CssDivider = ({right, margin}) => {
    const theme = useTheme();
    const classes = useStyles(theme);


    return (
        <Box 
            className={
                `${classes.mainContainer} ${!right ? classes.end : classes.start} ${!margin ? classes.margin1 : classes.margin2}`
            }
        >
            <Box className={right ? `${classes.rect} ${classes.leftToRight}` : `${classes.rect} ${classes.rightToLeft}`}/>
            <Box className={right ? `${classes.rect} ${classes.leftToRight}` : `${classes.rect} ${classes.rightToLeft}`}/>
        </Box>
    )
}