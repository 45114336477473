import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography
} from '@material-ui/core'
import { toDash } from '../../helpers/convertValue';
import deathGrave from '../../assets/img/death-grave.png';
import { galery } from '../../helpers/galeryImages';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    media: {
        height: 230,
    },
    content: {
        height: 120,
        overflow: 'auto',
    }
}))

export const SearchResult = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const renderCard = grave => {
        let img = grave.photos.length > 0 ? `${galery.url}${grave.photos[0]}` : deathGrave;
        return(
            <Card className={classes.card} key={grave.id_grob}>
                <CardActionArea
                    onClick={() => {
                        props.setGrave(grave.id_grob);
                    }}
                >
                    <CardMedia
                        alt={`img-${grave.category ? grave.category : grave.postage}`}
                        className={classes.media}
                        image={img}
                        title={grave.category ? grave.category : grave.postage}
                    />
                    <CardContent
                        className={classes.content}
                    >
                        {grave.persons.map(person => 
                            <Typography variant="subtitle2" key={person.id_person}>
                                {`- ${toDash(person.first_name)} ${toDash(person.last_name)}`}
                            </Typography>    
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }

    return(
        <Grid container spacing={4} alignItems='center'>
            {!props.graves.length && 
                
                    <Grid item xs={12} key='noresult'>
                        <Typography style={{margin: 20}} variant="h4" align="center">
                            Przepraszamy, nie można wyświetlić wyników dla tych kryteriów.
                        </Typography>
                    </Grid>
            }
            {props.graves.map(grave => 
                <Grid item xs={12} sm={6} md={4} lg={3} key={grave.id_grob}>
                    {renderCard(grave)}
                </Grid>
            )}
        </Grid>
    )
}