import React, { memo } from 'react';
import { 
    Box,
    Grid,
    Divider,
    Typography
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';
import { _breakpoints } from '../../../helpers/breakpoints';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        margin: '100px 0px',
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            margin: '75px 0px',
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            margin: '50px 0px',
        },
    },
    txt: {
        color: colors.black,
    },
    txtHeader: {
        marginBottom: 50,
    },
    divider: {
        backgroundColor: colors.red,
        marginTop: '-25px',
        width: '100%',
    },
    divider2Container: {
        margin: 'auto',
    },
    divider2: {
        backgroundColor: colors.red,
        width: '100%',
    }
}))

export const HeaderGrid = memo(({
    text, 
    direction
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const gridItems = [
        <Grid 
            item 
            xs={1} 
            key="header_item_1"
        />,
        <Grid 
            item 
            xs={5}
            key="header_item_2"
        >
            <Typography 
                className={`${classes.txt} ${classes.txtHeader}`} 
                variant="h3"
                align="center"
            >
                {text}
            </Typography>
            <Divider className={classes.divider} />
        </Grid>,
        <Grid 
            className={classes.divider2Container} 
            item 
            xs={6} 
            key="header_item_3"
        >
            <Divider className={classes.divider2} />
        </Grid>
    ];

    return (
        <Box className={classes.mainContainer}>
            <Grid container >
                {direction === 'right' && gridItems}
                {direction === 'left' && gridItems.reverse()}
            </Grid>
        </Box>
    )
})
