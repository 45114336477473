import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../helpers/colors';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Box } from '@material-ui/core';
import logo from '../../assets/img/logo_cj.png';
import logoweb from '../../assets/img/webp/logo_cj.webp'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 5,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.black_trans2
  },
  loaders: {
    display: 'flex'
  },
  logo: {
    maxWidth: '90vw'
  }
}));

export const MainLoader = React.memo(() => {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <picture>
          <source alt="logo-loader-small" srcset={logoweb} type="image/webp" />
          <img 
            alt="logo-loader"
            className={classes.logo}
            src={logo}
          />
        </picture>
        <Box className={classes.loaders}>
          <Loader type="ThreeDots" color={colors.white} height={50} width={150} />
          <Loader type="ThreeDots" color={colors.red} height={50} width={150} />
        </Box>
      </Backdrop>
    </div>
  );
})