import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { useSpeechSynthesis } from 'react-speech-kit';
import {
  Button
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ToolbarText } from './ToolbarText';


const useStyles = makeStyles((theme) => ({
    btn: {
        margin: '0 10px',
        [theme.breakpoints.down(1450)]: {
            margin: '0 0'
        }
    },
}))


export const ToolbarButton = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    // const { speak, cancel } = useSpeechSynthesis();

    return (
        <Button
            className={classes.btn}
            id={props.id}
            onClick={props.handleClick}
        >
            <ToolbarText translate={props.translate} />
        </Button>
    )
}