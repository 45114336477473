import React, { memo, useState, useEffect } from 'react';
import {
  Box
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';
import { _breakpoints } from '../../../helpers/breakpoints';

const useStyles = makeStyles((theme) => ({
    mapContainer: {
        backgroundColor: colors.white,
        paddingBottom: '20vh'
    },
    map: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export const GeoportalPage = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [url, setUrl] = useState("https://geoportal.gispro.pl/cemetery");
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if(props.coordinates){
            setUrl(
                `https://geoportal.gispro.pl/pl/cemetery/osm/${props.coordinates.longitude}/${props.coordinates.latitude}/20/200/0/-1.5708/0/0/null/n?lon=${props.coordinates.longitude}&lat=${props.coordinates.latitude}`
            )
        }
        setReady(true);
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.coordinates])

    return (
        <Box className={classes.mapContainer}>
            <Box className={classes.map} >
                { ready &&
                    <iframe id="geoportal_iframe"
                        title="GISPRO Geoportal"
                        src={url}
                        allow="clipboard-read; clipboard-write; fullscreen"
                        style={
                            props.isFullscreen ? 
                                {
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100vw',
                                    height: '100vh',
                                }
                            :
                                {
                                    position: 'relative',
                                    width: '80vw',
                                    height: '80vh',
                                    [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
                                        width: '100vw',
                                        height: '100vh'
                                    }
                                }
                        }
                    >
                    </iframe>
                }
            </Box>
        </Box>
    )
})