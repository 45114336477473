import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useSpeechSynthesis } from 'react-speech-kit';
import {
  Link,
  Breadcrumbs,
  Typography,
  Box,
  Divider
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../helpers/colors';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0 25px',
        paddingTop: 20,
        paddingBottom: 40
    },
    breadcrumbs: {
        paddingBottom: 10
    },
    text: {
        fontWeight: 700,
    },
    textLink: {
        fontWeight: 700,
    },
    divider: {
        backgroundColor: colors.black_trans
    }
}))


export const HistoryBread = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    // const { speak, cancel } = useSpeechSynthesis();
    const { t } = useTranslation();
    const history = useHistory();

    const handleClick = (e) => {
        e.preventDefault();
        props.setPath('/');
        props.setMenu({
            isHome: true,
            isSearch: false,
            isHistory: false,
            isGallery: false,
            isProject: false,
            isContact: false,
            isAdminPanel: false
          })

          history.push(`/home`);
    }

    return (
        <Box className={classes.container} style={props.isHome ? {backgroundColor: 'transparent'} : {backgroundColor: colors.white}} >
            {history.location.pathname === '/' || history.location.pathname.includes('home') || history.location.pathname.includes('admin')? '' : 
                <>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                        <Link className={classes.textLink} color="inherit" href="/" onClick={handleClick}>
                            {t('appBar.home')}
                        </Link>
                        <Typography 
                            className={classes.text}
                            color="textPrimary"
                        >
                            {t(`appBar.${props.path.replace('/', '')}`)}
                        </Typography>
                    </Breadcrumbs>
                    <Divider className={classes.divider} />
                </>
            }
        </Box>
    );
}