import React, { memo } from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { colors } from '../../helpers/colors';


const useStyles = makeStyles((theme) => ({
    galleryCont: {
        maxHeight: 750
    },
    galleryItem: {
        minWidth: 300,
        height: 240,
        margin: 20,
        backgroundColor: colors.black_trans
    },
    item: {
        cursor: 'pointer'
    }
}))

export const GalleryCarousel = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return props.action ?
            <AwesomeSlider className={`aws-btn ${classes.galleryCont}`}>
                {props.test.map((ele, index) => 
                    <div 
                        alt={`galery_aws_${index}`}
                        key={`galery_aws_${index}`}
                        className={classes.item} 
                        data-src={ele.original} 
                        onClick={e => props.action(ele)} 
                    />
                )}
            </AwesomeSlider>  
        :
            <Gallery>
                {props.test.map((ele, index) => {
                    return(
                        <Item
                            alt={`galery_card_${index}`}
                            key={`galery_card_${index}`}
                            original={ele.original}
                            thumbnail={ele.thumbnail}
                            width={ele.width}
                            height={ele.height}
                        >
                            {({ ref, open }) => {
                                return (
                                    <Card className={classes.galleryItem} onClick={open} ref={ref}>
                                        <CardActionArea>
                                            <CardMedia
                                                id={`img-${index}`}
                                                component="img"
                                                alt={`img-${index+1}`}
                                                height="240"
                                                image={ele.original}
                                                //title={`img-${index+1}`}
                                            />
                                        </CardActionArea>
                                    </Card>
                                )
                            }}
                        </Item>
                    )
                })}
            </Gallery>
})
