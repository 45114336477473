import React, { memo } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useSpeechSynthesis } from 'react-speech-kit';
import {
  Toolbar,
  AppBar,
  Box,
  IconButton
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import logo from '../../assets/img/logo_cj.png';
import logoweb from '../../assets/img/webp/logo_cj.webp';
import { HideOnScroll } from '../HideOnScroll/HideOnScroll';
// import { LanguageMenu } from '../LanguageMenu/LanguageMenu';
import { ToolbarMenu } from '../ToolbarMenu/ToolbarMenu';
import { colors } from '../../helpers/colors';




const useStyles = makeStyles((theme) => ({
    appBarContainer: {
        paddingTop: 50,
    },
    appBar: {
        zIndex: 3,
        backgroundColor: colors.black_trans,
        boxShadow: 'none',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 0,
        [theme.breakpoints.down(1120)]: {
            marginLeft: 'calc(50vw - 80px)'
        },
    },
    logo: {
        margin: 'auto 0',
        padding: 20,
        maxHeight: 120
    },
    left: {
        paddingTop: 40,
        paddingRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        zIndex: 4
    },
    middle: {
        paddingTop: 40,
        paddingRight: 10,
        display: 'none',
        [theme.breakpoints.up(1120)]: {
            display: 'block'
        },
    },
    right: {
        display: 'flex',
        paddingTop: 22,
        alignSelf: 'baseline',
        [theme.breakpoints.up(1120)]: {
            paddingTop: 40,
            alignSelf: 'auto',
            display: 'block'
        },
        zIndex: 4
    },
    menuButton: {
        position: 'fixed',
        zIndex: 5,
        top: 25,
        left: 25,
        display: 'block',
        [theme.breakpoints.up(1120)]: {
          display: 'none'
        }
      },
    iconContainer: {
    },
    icon: {
        color: colors.black,
        fontWeight: 700,
        fontSize: '2rem',
        backgroundColor: colors.white_trans,
        borderRadius: 5,
    },
    txt: {
        textAlign: 'center',
        color: colors.white,
        fontWeight: 700,
        textShadow: '1px  1px 2px black, 1px -1px 2px black, -1px  1px 2px black, -1px -1px 2px black'
    }
}))


export const AppBarTop = memo((props) => {
    const theme = useTheme();
    const classes = useStyles(theme);


    return (
        <Box className={classes.appBarContainer} id="toolbar">
            <HideOnScroll {...props} menuButton >
                <Box id="menuButton" className={classes.menuButton}>
                    <IconButton
                        aria-label="mobile-menu"
                        className={classes.iconContainer}
                        onClick={e => props.setIsHamburger(!props.isHamburger)}
                    >
                        {props.isHamburger ?
                            <MenuIcon className={classes.icon} />
                        :
                            <CloseIcon className={classes.icon} />
                        }
                    </IconButton>
                </Box>
            </HideOnScroll>
            <HideOnScroll {...props}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>                   
                        <Box className={classes.left}>
                        <picture>
                            <source alt="logo-small" srcset={logoweb} type="image/webp" />
                            <img 
                                alt="logo-small"
                                className={classes.logo}
                                src={logo} 
                            />
                        </picture>
                        </Box>
                        <Box className={classes.middle}>
                            <ToolbarMenu 
                                path={props.path}
                                setPath={props.setPath}
                                menu={props.menu}
                                setMenu={props.setMenu}  
                                isHamburger={props.isHamburger}
                                setIsHamburger={props.setIsHamburger}  
                                setFirstName={props.setFirstName}
                                setLastName={props.setLastName}
                                setRegion={props.setRegion}
                                setCoordinates={props.setCoordinates}
                                setGalleryPage={props.setGalleryPage}
                                setGrave={props.setGrave}
                                type="AppBarTop"
                            />
                        </Box>
                        <Box className={classes.right}>
                            {/* <LanguageMenu 
                                language={props.language}
                                setLanguage={props.setLanguage}
                            /> */}
                        </Box>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar />
        </Box>
    )
})