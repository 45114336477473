export const generateSearchUrl = (firstName, lastName, region, cemetery, pagination, maxCount) => {
    let url = "/search";
    let query = [];
    if(firstName)
        query.push(`firstName=${firstName}`);
    if(lastName)
        query.push(`lastName=${lastName}`);
    if(region)
        query.push(`region=${region}`);
    if(cemetery)
        query.push(`cemetery=${cemetery}`)
    if(pagination)
        query.push(`pagination=${pagination <= maxCount ? pagination : maxCount}`);


    if(query.length > 0){
        url += `?${query[0]}`;
        for(let i = 1; i < query.length; i++){
            url += `&${query[i]}`;
        }
    }

    return url;
}