import React, { memo } from 'react';
import {
  Box,
  Link,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../../helpers/colors';
import logo_us from '../../../assets/img/logo_us.jpg';
import logo_us_web from '../../../assets/img/webp/logo_us.webp';
import logo_us_instytut from '../../../assets/img/logo_us_instytut.jpg';
import logo_us_instytut_web from '../../../assets/img/webp/logo_us_instytut.webp';
import logo_nprh from '../../../assets/img/nprh_logo.png';
import logo_nprh_web from '../../../assets/img/webp/nprh_logo.webp';
import showPhoto from '../../../assets/img/ShowFoto.jpg';
import { _breakpoints } from '../../../helpers/breakpoints';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: colors.white,
        paddingBottom: '20vh',
        textAlign: 'justify',
        '& h3': {
            fontSize: '2.5vw',
        },
        '& h6': {
            fontSize: '1.1vw'
        },
        [theme.breakpoints.down(_breakpoints.laptopL.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.laptopL.fontSizeMultiply * 2}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.laptopL.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.laptop.fontSizeMultiply * 2.2}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.laptop.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.tablet.fontSizeMultiply * 1.8}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.tablet.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.mobileL.fontSizeMultiply * 1.5}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.mobileL.fontSizeMultiply}vw`,
            },
        },
        [theme.breakpoints.down(_breakpoints.mobileM.size)]: {
            '& h3': {
                fontSize: `${_breakpoints.mobileM.fontSizeMultiply * 1.4}vw`,
            },
            '& h6': {
                fontSize: `${_breakpoints.mobileM.fontSizeMultiply}vw`,
            },
        },
    },
    txt: {
        color: colors.black,
    },
    txtHeader: {
        marginBottom: 50
    },
    txtContent: {
        padding: 10,
        width: 'calc(90vw)',
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            width: 'calc(75vw)'
        },
    },
    linkContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    link: {
        cursor: 'pointer'
    },
    logosContainer: {
        padding: 25,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down(_breakpoints.laptop.size)]:{
            flexDirection: 'column'
        }
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        width: '100%'
    },
    logo_ins: {
        maxHeight: 150,
        maxWidth: '30vw',
        margin: 'auto',
        cursor: 'pointer',
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            maxWidth: '50vw'
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            maxWidth: '60vw'
        },
        [theme.breakpoints.down(_breakpoints.mobileL.size)]: {
            maxWidth: '80vw'
        },
    },
    logo_us: {
        maxHeight: 150,
        maxWidth: '20vw',
        margin: 'auto',
        cursor: 'pointer',
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            maxWidth: '30vw'
        },
        [theme.breakpoints.down(_breakpoints.tablet.size)]: {
            maxWidth: '40vw'
        }
    },
    logo_nprh: {
        margin: 'auto',
        cursor: 'pointer',
        maxWidth: '40vw',
        [theme.breakpoints.down(_breakpoints.laptop.size)]: {
            maxWidth: '50vw'
        },
    },
    picture_ins: {
        width: '100%',
        display: 'flex',
        padding: 5
    },
    picture_logo: {
        width: '100%',
        display: 'flex',
        padding: 20
    },
    picture_nprh: {
        width: '100%',
        display: 'flex',
        padding: 20
    },
    grow: {
        flexGrow: 1
    },
    showPhoto: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 20,
        maxWidth: '80%'
    }
}))

export const AboutPage = memo(props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Box className={classes.mainContainer}>
            <Typography className={`${classes.txt} ${classes.txtHeader}`} variant="h3" align="center">
                O projekcie
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Projekt dotyczący Cmentarza Janowskiego realizowany był w latach 2014–2017 na podstawie grantu naukowego <b>Lapides clamabunt. 
                Cmentarz Janowski we Lwowie. Polskie dziedzictwo narodowe</b> w ramach konkursu Narodowego Programu Rozwoju Humanistyki Ministerstwa 
                Nauki i Szkolnictwa Wyższego (numer rejestracyjny 11H 13 0151 82) przez zespół naukowców z Uniwersytetu Szczecińskiego w składzie: 
                <blockquote>
                    - prof. dr. hab. Ryszard Tomczyk (kierownik grantu)
                </blockquote>
                <blockquote>
                    - dr Barbara Patlewicz (wykonawca merytoryczny, autorka zdjęć)
                </blockquote>
            </Typography>
            <img 
                src={showPhoto}
                className={classes.showPhoto}
                alt="dr Barbara Patlewicz, prof. dr. hab. Ryszard Tomczyk" 
            />
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Badania polegały na zinwentaryzowaniu wszystkich zachowanych na cmentarzu grobów z inskrypcją w języku polskim, kwerendzie w miejscowych 
                archiwach, przeglądzie prasy z przełomu XIX i XX wieku (w większości zachowanej jedynie w Lwowskiej Narodowej Naukowej Bibliotece Ukrainy 
                im. Wasyla Stefanyka. Niezwykle cenne okazały się również informacje przekazane przez bliskich i członków rodzin osób pochowanych na tej 
                nekropolii. Zebrane dane i dokumentacja fotograficzna projektu oddają stan na dzień 31 stycznia 2018 r.
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Zwieńczeniem badań były publikacje:
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                <blockquote>
                    album prezentujący najpiękniejsze i najbardziej interesujące przykłady sztuki sepulkralnej, które się zachowały: 
                    <blockquote>
                        Barbara Patlewicz, Ryszard Tomczyk: <i><b>Cmentarz Janowski we Lwowie.</b></i> Szczecin: Print Group, 2016.
                    </blockquote>
                    dwutomowa monografia:
                    <blockquote>
                        Barbara Patlewicz, Ryszard Tomczyk: <i><b>Cmentarz Janowski we Lwowie: polskie dziedzictwo narodowe.</b></i> T. 1-2, Szczecin: Zapol Sobczyk Spółka Jawna, 2017. 
                    </blockquote>
                    oraz artykuły naukowe autorstwa prof. dr. hab. Ryszarda Tomczyka:
                    <blockquote>
                        <i><b>Zwyczaje pogrzebowe i usługi pogrzebowe we Lwowie na przełomie XIX i XX wieku Historia Slavorum Occidentis</b></i> 2017, nr 4 (15), s. 71–87,
                    </blockquote>
                    <blockquote>
                        <i><b>Cmentarz jako źródło historyczne.</b></i> Na przykładzie <i><b>Cmentarza Janowskiego we Lwowie</b></i> opublikowany w czasopiśmie naukowym: <i><b>Historia Slavorum Occidentis</b></i> 2019, nr 3 (22), s. 63–99.
                    </blockquote>
                </blockquote>     
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent} ${classes.linkContainer}`} variant="h6">
                W 2017 roku druga z wyżej wymienionych publikacji otrzymała nagrodę
                <Link 
                    className={`${classes.link} ${classes.txtContent}`}
                    onClick={() => window.open('http://www.ksiazkahistorycznaroku.pl/34929444/ksiazka-historyczna-roku-wybrana-po-raz-dziesiaty', '_blank').focus()}
                >
                    im. Oskara Haleckiego dla najlepszej Historycznej Książki Roku.
                </Link>
                <Link 
                    className={`${classes.link} ${classes.txtContent}`}
                    onClick={() => window.open('https://his.usz.edu.pl/lapides-clamabunt-cmentarz-janowski-we-lwowie-polskie-dziedzictwo-narodowe/', '_blank').focus()}
                >
                    Wyniki badań spotkały się z dużym zainteresowaniem zarówno w Polsce, jak i zagranicą.
                </Link>
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Od 2018 r. dr Barbara Patlewicz i prof. dr hab. Ryszard Tomczyk realizują kolejny projekt badawczy:
                <blockquote>
                    <i><b>Cmentarze na przedmieściach Lwowa. Polskie dziedzictwo narodowe </b></i> (grant badawczy 2018-2023, nr 01SPN17003518, 
                    konkurs Ministerstwa Nauki i Szkolnictwa Wyższego w ramach programu <i>Szlakami Polski Niepodległej</i>). 
                </blockquote>
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Badania te dotyczą cmentarzy w takich podlwowskich miejscowościach i dzielnicach Lwowa jak:
                <blockquote>
                    Biłohorszcze, Hołosko, Krzywczyce, Kulparków, Lesienice, Lewandówka, Malechów, Pasieki Zubrzyckie, Rudno, Rzęsna Polska i Rzęsna Ruska, 
                    Sichów, Skniłów, Sokolniki, Sołonka, Sygniówka, Zamarstynów, Winniki, Zboiska, Zniesienie, Zimna Woda, Zubrza.
                </blockquote>
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Dane z powyższych cmentarzy dotyczące osób zmarłych i pochówków będą sukcesywnie wprowadzane do bazy; na niniejszej stronie zostanie 
                udostępniona możliwość ich wyszukiwania z uwzględnieniem wyboru poszczególnych cmentarzy. 
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Planowane jest również dokładne zlokalizowanie w przestrzeni pochówków na Cmentarzu Janowskim i uzupełnienie bazy o ich współrzędne geograficzne. 
                Aktualnie istnieje możliwość zlokalizowania pola, na którym znajduje się szukany grób.
            </Typography>
            <Typography className={`${classes.txt} ${classes.txtContent}`} variant="h6">
                Opracowanie i wdrożenie strony internetowej wraz z geoportalem dla Cmentarza Janowskiego we Lwowie zostało sfinansowane ze środków 
                Uniwersytetu Szczecińskiego w ramach inicjatywy podjętej przez Instytut Historyczny US (umowa A-ZP.384.119.2021).
            </Typography>
            <Box
                className={classes.logosContainer}
            >
                <Box className={classes.logoContainer} >
                    <picture className={classes.picture_nprh}>
                        <source 
                            alt="Logotyp Narodowy Program Rozwoju Humanistyki © - small" 
                            srcset={logo_nprh_web}
                            type="image/webp"
                        />
                        <img 
                            className={classes.logo_nprh}
                            alt="Logotyp Narodowy Program Rozwoju Humanistyki ©"
                            src={logo_nprh}
                            onClick={e => window.open('https://www.gov.pl/web/edukacja-i-nauka/narodowy-program-rozwoju-humanistyki', '_blank').focus()}
                        />
                    </picture>
                </Box>
                <Box className={classes.logoContainer} >
                    <picture className={classes.picture_ins}>
                        <source 
                            alt="Logotyp Uniwersytet Szczeciński © - small" 
                            srcset={logo_us_web} 
                            type="image/webp" 
                        />
                        <img
                            className={classes.logo_us}
                            alt='Logotyp Uniwersytet Szczeciński ©'
                            src={logo_us}
                            onClick={e => window.open('https://usz.edu.pl/', '_blank').focus()}
                        />
                    </picture>
                </Box>
                <Box className={classes.logoContainer} >
                    <picture className={classes.picture_logo}>
                        <source 
                            alt="Logotyp Instytut Historyczny Uniwersytetu Szczecińskiego © - small" 
                            srcset={logo_us_instytut_web} 
                            type="image/webp" 
                        />
                        <img
                            className={classes.logo_ins}
                            alt='Logotyp Instytut Historyczny Uniwersytetu Szczecińskiego ©'
                            src={logo_us_instytut}
                            onClick={e => window.open('https://his.usz.edu.pl/', '_blank').focus()}
                        />
                    </picture>
                </Box>
            </Box>
        </Box>
    )
})