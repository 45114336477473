import { toDash } from './convertValue';

export const convertGraveToCsv = grave => {
    let csvContent = "data:text/plain;charset=utf-8,";

    //add grave values
    csvContent += "Informacje o nagrobku:\r\n";
    csvContent += "\r\n";
    csvContent += `Id grobu: ${toDash(grave.id_grob)}\r\n`;
    csvContent += `Rodzaj grobu: ${toDash(grave.type)}\r\n`;
    csvContent += `Kategoria grobu: ${toDash(grave.category)}\r\n`;
    csvContent += `Dokres grobu: ${toDash(grave.postage)}\r\n`;
    csvContent += `Inskrypcja: ${toDash(grave.inscription)}\r\n`;

    //add persons value
    csvContent += "\r\nInfromacje o zmarłych:\r\n";
    grave.persons.forEach(person => {
        csvContent += "\r\n";
        csvContent += `Id zmarłego: ${toDash(person.id_person)}\r\n`;
        csvContent += `Imie: ${toDash(person.first_name)}\r\n`;
        csvContent += `Drugie imie: ${toDash(person.second_name)}\r\n`;
        csvContent += `Nazwisko: ${toDash(person.last_name)}\r\n`;
        csvContent += `Z domu: ${toDash(person.from_home)}\r\n`;
        csvContent += `Data urodzenia: ${toDash(person.date_of_birth)}\r\n`;
        csvContent += `Data zgonu: ${toDash(person.date_of_death)}\r\n`;
        csvContent += `Długość życia: ${toDash(person.life_length)}\r\n`;
        csvContent += `Zawód: ${toDash(person.profession)}\r\n`;
        csvContent += `Uwagi do zawodu: ${toDash(person.profession_remarks)}\r\n`;
        csvContent += `Zawód męża: ${toDash(person.husband_profession)}\r\n`;
        csvContent += `Uwagi do zawodu męża: ${toDash(person.husband_profession_remarks)}\r\n`;
    })

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `grob_${grave.id_grob}.txt`);
    document.body.appendChild(link);
    link.click();
}