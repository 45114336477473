import React, { useState, memo} from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

export const HideOnScroll = memo((props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  const [hideOnScroll, setHideOnScroll] = useState(true)

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y > -50
    if (isShow !== hideOnScroll) setHideOnScroll(isShow)
  }, [hideOnScroll])

  return (
    <Slide appear={false} direction="down" in={props.menuButton ? !trigger : hideOnScroll}>
      {children}
    </Slide>
  );
})