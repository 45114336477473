import React, { memo } from 'react';
import { 
    Backdrop
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { colors } from '../../helpers/colors';
import { ToolbarMenu } from '../ToolbarMenu/ToolbarMenu';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        backgroundColor: colors.black_trans2,
        overflow: 'auto',
        paddingLeft: '10vw'
    }
}))

export const MobileMenu = memo(({
    isHamburger, 
    path, 
    menu, 
    setIsHamburger, 
    setPath, 
    setMenu,
    setFirstName,
    setLastName,
    setRegion,
    setCoordinates,
    setGalleryPage,
    setGrave
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return !isHamburger &&
        <Backdrop 
            className={classes.backdrop} 
            open={!isHamburger} 
        >
            <ToolbarMenu 
                path={path}
                setPath={setPath}
                menu={menu}
                setMenu={setMenu}  
                isMobile={true}  
                isHamburger={isHamburger}
                setIsHamburger={setIsHamburger}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setRegion={setRegion}
                setCoordinates={setCoordinates}
                setGalleryPage={setGalleryPage}
                setGrave={setGrave}
                type="MobileMenu"
            />
        </Backdrop>
})