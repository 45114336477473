import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useSpeechSynthesis } from 'react-speech-kit';
import {
  Box
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ToolbarButton } from './ToolbarButton';
import { menuItems } from '../../helpers/menuItems';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
    toolbarContainer: {
        
    },
    toolbarContainerMobile: {
        marginTop: 150,
        marginBottom: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        '& button': {
            margin: 7.5
        }
    }
}))


export const ToolbarMenu = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const classes = useStyles(theme);
    const history = useHistory();
    // const { speak, cancel } = useSpeechSynthesis();

    const renderButton = name =>
        <ToolbarButton
            key={`${props.type}_${name}`}
            id={`${props.type}_${name}`}
            translate={t(`appBar.${name}`)}
            handleClick={handleClick}
            isFooter={props.isFooter}
        />
        
    const handleClick = e => {
        const id = e.currentTarget.id.split('_')[1];
        const resetStates = {
            isHome: false,
            isHistory: false,
            isSearch: false,
            isGeoportal: false,
            isGallery: false,
            isProject: false,
            isContact: false,
            isAdminPanel: false
        }

        props.setFirstName('');
        props.setLastName('');
        props.setRegion('');
        props.setGrave('');
        props.setCoordinates(null);
        props.setGalleryPage(1);
        window.scrollTo(0, 0);

        props.setMenu({
            ...resetStates,
            [`is${capitalizeFirstLetter(id)}`]: true
        })

        const location = id.toLowerCase();
        history.push(location)
        props.setPath(location)

        if(props.isMobile && !props.isHamburger) props.setIsHamburger(true);
    }

    return (
        <Box 
            className={props.isMobile ? classes.toolbarContainerMobile : classes.toolbarContainer}
        >
            {menuItems.map(ele => renderButton(ele))}
        </Box>
    )
}